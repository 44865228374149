<template>
    <div>
        <a-modal :visible="showReceive" title="領取獎勵" :closable="false" :centered="true">
            <div class="receive-content">
                <span class="r-tips tips">綁定郵箱獎勵未領取，是否領取？</span>
                <span class="r-tips tips">禮品碼將發送至您的綁定郵箱</span>
                <span class="bd-e tips">{{ '綁定郵箱：' + bingdingEmail }}</span>
                <span class="bd-e wu">郵箱有誤? <a @click="changeBd">點此修改</a></span>
            </div>
            <template #footer>
                <!-- <a-button key="back" @click="handleCancel">取消</a-button> -->
                <div style="width: 100%;text-align: center; padding: 8px 0px 12px;">
                    <a-button key="submit" type="primary" @click="receiveConfig" style="width: 30%;">領取獎勵</a-button>
                    <a-button key="submit" @click="cancelReceive" style="width: 30%; margin-right: 10px;">暂不領取</a-button>

                </div>
            </template>
        </a-modal>
        <a-modal :visible="showNotBd" :closable="false" :centered="true">
            <div class="receive-content">
                <span class="r-tips tips">當前帳號未綁定郵箱，為了避免帳號丟失，建議您盡快綁定喔~</span>
                <span class="bd-e wu">完成綁定可獲得禮品獎勵一份！！！</span>
            </div>
            <template #footer>
                <!-- <a-button key="back" @click="handleCancel">取消</a-button> -->
                <div style="width: 100%;text-align: center; padding: 8px 0px 12px;">
                    <a-button key="submit" type="primary" @click="bdNow" style="width: 30%; margin-right: 10px;">立即绑定</a-button>
                    <a-button key="submit" @click="notYetTips" style="width: 30%; margin-left: 10px;">暂不提醒</a-button>
                </div>
            </template>
        </a-modal>
        <a-modal :visible="showNotYet" :closable="false" :centered="true">
            <div class="receive-content">
                <span class="bd-e wu">及時綁定郵箱有助於保證帳號安全哦，還有禮品獎勵，您確定要拒絕嗎？</span>
            </div>
            <template #footer>
                <!-- <a-button key="back" @click="handleCancel">取消</a-button> -->
                <div style="width: 100%;text-align: center; padding: 8px 0px 12px;">
                    <a-button key="submit" type="primary" @click="goToBd" style="width: 30%; margin-right: 10px;">前往绑定</a-button>
                    <a-button key="submit" @click="nextTime" style="width: 30%; margin-left: 10px;">下次再說</a-button>
                </div>
            </template>
        </a-modal>
        <a-modal :visible="showBdMail" title="綁定郵箱" :closable="false" :centered="true">
            <span v-show="bdParams.TYPE">{{ '已绑定邮箱：' + bingdingEmail }}</span>
            <div class="email-item display-f flex-row-jl-c">
                <label for="email" style="flex: 1; text-align: right;"><i>*</i>&nbsp;{{ bdParams.TYPE ? '新郵箱：' : '郵箱：'}}</label>
                <a-input id="email" @change="(e) => { errorText.eNumber = ''; bdParams.EMAIL = e.target.value }" placeholder="請輸入您的郵箱" style="width: 75%;" />
                <span class="tips">{{ errorText.eNumber }}</span>
            </div>
            <div class="email-item display-f flex-row-jl-c">
                <label for="code"  style="flex: 1; text-align: right;"><i>*</i>&nbsp;驗證碼：</label>
                <a-input-group id="code" compact  style="width: 75%;">
                    <a-input v-model:value="bdParams.AuthCode" @change="() => { errorText.eCodeTips = '' }" placeholder="請輸入驗證碼" style="width: 56%" />
                    <a-button v-if="bdParams.TYPE == 0" type="primary" @click="getAuthCode(0)" :loading="codeLoading">{{ errorText.eCode ? '重新獲取' : '獲取驗證碼'}}</a-button>
                    <a-button v-if="bdParams.TYPE == 1" type="primary" @click="getAuthCode(2)" :loading="codeLoading">{{ errorText.eCode ? '重新獲取' : '獲取驗證碼'}}</a-button>
                </a-input-group>
                <span class="tips">{{ errorText.eCodeTips }}</span>
            </div>
            <template #footer>
                <!-- <a-button key="back" @click="handleCancel">取消</a-button> -->
                <div style="width: 100%;text-align: center; padding: 8px 0px 12px;">
                    <a-button key="submit" type="primary" @click="bdConfig" :loading="bindLoading" style="width: 30%; margin-right: 10px;">確定綁定</a-button>
                    <a-button key="submit" @click="cancelBd" style="width: 30%; margin-right: 10px;">取消</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
import { getReqData, sendAuthCodeAPI, receiveGiftAPI, bindingEmailAPI } from '../../api/requests';
import secret from '../../api/secret'

export default {
    name: 'receivetips',
    data () {
        return {
            showReceive: false,
            bingdingEmail: '',
            showNotBd: false,
            showNotYet: false,
            showBdMail: false,
            bdParams: {
                UID: '', // 用户UID，必传
                UNAME: '', // 用户账号，必传
                EMAIL: '', // 邮箱，必传
                AuthCode: '', // 验证码，必传
                TYPE: '', // 类型（0为绑定邮箱，1为修改邮箱）
            },
            errorText: {
                eNumber: '',
                eCodeTips: '',
                eCode: ''
            },
            bindLoading: false,
            codeLoading: false
        }
    },
    props: {
        communication: {
            type: Object
        }
    },

    created() {
        if (this.communication.email) {
            this.bingdingEmail = this.communication.email.replace(/(\d{3})\d*(\d{2})/, '$1****$2');
            this.showNotBd = false;
            if (this.communication.giftcode.email) {
                this.showReceive = false;
            } else {
                this.showReceive = true;
            }
        } else {
            this.showNotBd = true;
        }
    },
    mounted() {
    },

    methods: {
        receiveConfig() {
            let parStr = {};
            parStr.gifttype = 'email'; // 领取的礼包类型
            parStr.uname = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            parStr.uid = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            parStr.token = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token;
            parStr.pgid = 10;
            parStr.language = 1;
            receiveGiftAPI(getReqData(parStr)).then((res) => {
                if (res.data.code == 0) {
                    this.$message.success('領取成功！');
                    this.showReceive = false;
                } else {
                    this.$message.success('領取失敗！' + res.data.msg);
                }
            }).catch((data) => {
                console.log('[error]', data);
            })
        },
        cancelReceive() {
            this.showReceive = false;
        },
        changeBd() {
            this.showBdMail = true;
            this.showReceive = false;
            this.bdParams.TYPE = 1;
        },
        notYetTips() {
            this.showNotBd = false;
            this.showNotYet = true;
        },
        bdNow() {
            this.showBdMail = true;
            this.showNotBd = false;
            this.bdParams.TYPE = 0;
        },
        goToBd() {
            this.showBdMail = true;
            this.showNotYet = false;
            this.bdParams.TYPE = 0;
        },
        nextTime() {
            this.showNotYet = false;
        },
        cancelBd() {
            if (this.bdParams.TYPE) {
                this.showBdMail = false;
                this.showReceive = true;
            } else {
                this.showBdMail = false;
                this.showNotBd = true;
            }
            
            this.bdParams.TYPE = '';
        },
        bdConfig() {
            if (!this.bdParams.EMAIL) {
                this.errorText.eNumber = '郵箱號不能為空！';
                return
            }
            if (!this.bdParams.AuthCode) {
                this.errorText.eCodeTips = '請輸入驗證碼!';
                return
            }

            this.bindLoading = true;
            this.bdParams.UID = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            this.bdParams.UNAME = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            bindingEmailAPI(this.bdParams).then((res) => {
                if (res.data.code == -5) {
                    this.$message.error('驗證碼錯誤,請重新輸入!');
                }
                if (res.data.code == -6) {
                    this.$message.error('該用戶已綁定郵箱!');
                }
                if (res.data.code == 1) {
                    this.$message.success('綁定成功!');
                    this.showBdMail = false;
                }
                if (res.data.code == -7) {
                    this.$message.error('綁定失敗，該郵箱已綁定其他用戶!');
                }
                if (res.data.code == 0 || res.data.code == -2 || res.data.code == -3 || res.data.code == -4) {
                    this.$message.error('綁定失敗!');
                }
                this.bdParams.AuthCode = '';
                this.bindLoading = false;
            })
        },
        getAuthCode(type) {
            this.codeLoading = true;
            let codeParams = {};
            codeParams.UID = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            codeParams.EMAIL = this.bdParams.EMAIL;
            codeParams.TYPE = type;
            codeParams.LANGUE = 1;
            if (!codeParams.EMAIL) {
                this.errorText.eNumber = '郵箱號不能為空！';
                this.codeLoading = false;
                return
            }
            sendAuthCodeAPI(codeParams).then((res) => {
                this.codeLoading = false;
                if (res.data.code == 1) {
                    this.$message.success('發送成功!請到郵箱查看驗證碼。');
                } else if (res.data.code == -3) {
                    this.$message.error('發送失敗請重新獲取!');
                    this.errorText.email.eCodeTips = '發送失敗請重新獲取!';
                    this.errorText.email.eCode = true;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.receive-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .r-tips {
        font-weight: bold;
    }
    .wu {
        color: red;
    }
    .bd-e {
        margin: 16px 0px 0px;
    }
    .tips {
        font-size: 14px;
    }
}
.email-item {
    padding-bottom: 14px;
    margin-top: 10px;
    position: relative;
    .tips{
        position: absolute;
        left: 78px;
        bottom: -4px;
        font-size: 12px;
        color: red;
    }
    i{
        color: red;
    }
}
</style>
