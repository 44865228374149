import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: '',
    token: '',
    rolerInfo: ''
  },
  mutations: {
    saveUserInfo (state, info) {
      state.userInfo = info;
    },
    saveRolerInfo (state, info) {
      state.rolerInfo = info;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    headImg: state => state.headImg,
    name: state => state.name,
    token: state => state.token
  }
})
