import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'


import './rem'
import './assets/css/public.css'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import createFingerprint from './api/getDuid'

createFingerprint()

// import VConsole from 'vconsole'
// const vConsole = new VConsole();

const app = createApp(App)

app.use(store)
app.use(router)
app.use(Antd)
app.use(VueClipboard)
// app.use(vConsole)

app.mount('#app')
