<template>
  <Top v-show="$route.meta.showNav" />
  <!-- <div id="nav" class="display-f flex-row-jl-c">
    <div
      v-for="item in routeData" :key="item.id"
      class="nav-item display-f flex-row-jc-c"
      :class="item.check ? 'nav-item2' : ''"
       @click="checkNav(item)"
    >
      <span>{{ item.routeName }}</span> -->
      <!-- <router-link :to="item.url"></router-link> -->
    <!-- </div> -->
    <!-- <div class="nav-item display-f flex-row-jc-c">
      <router-link to="/about">About</router-link>
    </div> -->
  <!-- </div> -->
  <router-view/>
  <Foot v-if="footShow & $route.meta.showNav" />
  <!-- <div class="bg-video">
    <video class="v1"  autoplay muted loop>
        <source src="./assets/img/bg-video.mp4">
    </video>
  </div> -->
</template>

<script>
import Top from './views/top.vue'
import Foot from './views/foot.vue'
  export default {
    components: {
      Top,
      Foot
    },
    data () {
      return {
        footShow: true,
        routeData: [
          { id: 1, routeName: '首頁', check: true, url: '/' },
          { id: 2, routeName: 'New', check: false, url: '/journalism' },
          { id: 3, routeName: 'About', check: false, url: '/about' },
          { id: 3, routeName: '儲值中心', check: false, url: '/recharge' },
        ]
      }
    },
    created () {
      if (window.isPC == 1) {
        this.footShow = true;
      } else {
        this.footShow = false;
      }
      window.addEventListener("popstate", function(e) {
        console.log('【e】', e);
      }, false);
    },
    methods: {
      checkNav (item) {
        this.routeData.forEach((components) =>{
          components.check = false;
        })
        item.check = true;
        this.$router.push({ path: item.url })
      }
    }
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: #f6f7fb;
    height: 100%;
  }

  #nav {
    width: 1310px;
    height: 50px;
    background: #a6d0ee;
    margin-bottom: 8px;
    .nav-item {
      width: 120px;
      height: 100%;
      border-right: 1px solid #ffffff;
      span {
        font-weight: bold;
        color: #fff;
        text-decoration: none;
      }
    }
    .nav-item2 {
      background: rgb(255, 145, 0);
    }
    .nav-item:hover {
      background: rgb(255, 145, 0);
    }
  }
  .bg-video {
    width:100%;
    height:auto;
    display:block;
    position:absolute;
    left:0px;
    top:0px;
    z-index: -1;
    vertical-align:bottom;
    .v1 {
      width: 100%;
      height: 100%;
    }
  }
</style>
