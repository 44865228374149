<template>
    <div class="strategy-box">
        <div class="display-f flex-row-js-c top">
            <div>
                <a-button size="small" @click="getStrategy">返回</a-button>
            </div>
            <div>
                <a-button type="primary" size="small" @click="release">发布</a-button>
            </div>
        </div>
        <div class="edit-box">
            <div class="title-box display-f flex-row-jl-c">
                <label for="title"><i>*</i>&nbsp;文章標題：</label>
                <input id="title" type="text" autoComplete="off" v-model="params.GTITLE" placeholder="請輸入文章標題">
            </div>
            <div id="edit"></div>
            <div class="upload-picture">
                <span>封面图:</span>
                <a-upload
                    list-type="picture-card"
                    :file-list="fileList"
                    @preview="handlePreview"
                    @change="handleChange"
                    :beforeUpload="beforeUpload"
                >
                    <div v-if="fileList.length < 8">
                        <div class="ant-upload-text">
                            上传图片
                        </div>
                    </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
            </div>
        </div>
    </div>
</template>

<script>
import E from 'wangeditor';
// import secret from '../api/secret'
import { addArticleAPI, upFilePictureAPI } from '../api/requests';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
    name: 'addstrategy',

    data() {
        return {
            params: {
                UID: '',		//uid
                UNAME: '',		//登录账号
                GTITLE: '',		//标题
                GCONTENT: '',	//内容
                COVERURL: '' 	//封面图
            },
            previewVisible: false,
            previewImage: '',
            fileList: [],
            uploadFile: {
                file: ''
            }
        }
    },

    mounted() {
        let _this = this;
        const editor = new E(document.getElementById('edit'));
        editor.config.uploadImgShowBase64 = true;
        editor.config.onchange = function (newHtml) {
            _this.params.GCONTENT = newHtml;
            console.log("【编辑数据】", _this.params);
        };
        editor.create();
    },

    methods: {
        getStrategy() {
            this.$emit('childValue', 'list');
        },
        release() {
            this.params.UID = '6018adee7c29rq7852'
            // JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            this.params.UNAME = 'bob123456'
            // JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            if (!this.params.GTITLE) {
                this.$message.error('文章標題不能為空！');
                return
            }
            if (!this.params.GCONTENT) {
                this.$message.error('文章內容不能為空！');
                return
            }
            if (!this.uploadFile) {
                this.$message.error('请上传封面！');
                return
            }

            console.log('【发布】', this.uploadFile);
            upFilePictureAPI(this.uploadFile).then((res) => {
                console.log('【上传封面】', res.data);
                if (res.data.code == 1) {
                    this.addArticle()
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch((data) => {
                console.log('【失败】', data);
            })
        },
        handleCancel() {
            this.previewVisible = false;
        },
        async handlePreview(file) {
            console.log('【file】', file);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({ fileList }) {
            this.fileList = fileList;
            console.log('【this.fileList】', this.fileList);
        },
        beforeUpload(filePicture) {
            console.log('【上传前】', filePicture);
            this.uploadFile.file = filePicture;
            return false;
        },
        addArticle() {
            console.log('【发布】', this.params);
            addArticleAPI(this.params).then((res) => {
                console.log('【添加文章】', res);
            }).catch((data) => {
                console.log('【失败】', data);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.strategy-box {
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
        padding-bottom: 12px;
        border-bottom: 1px solid #cdcdcd;
    }
    .edit-box {
        flex: 1;
        overflow: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            // width: 3px;
            display: none;
        }
        // &::-webkit-scrollbar-thumb {
        //     background: #dedede;
        //     border-radius: 10px;
        // }
        // &::-webkit-scrollbar-track-piece {
        //     background: transparent;
        // }
        .title-box {
            position: relative;
            padding: 4px 0px 16px;
            margin: 12px 0px 0px;
            i {
                color: red;
            }
            .label {
                display: block;
                width: 62px;
                /* width: 0.292rem; */
                /* padding: 0px 2px; */
                font-size: 16px;
                text-align: right;
            }
            input {
                flex: 1;
                /* width: 1.03rem; */
                height: 42px;
                border: 1px solid #ccc;
                border-radius: 6px;
                padding: 4px 8px;
            }
            input:focus {
                outline:none;
                box-shadow: 0px 0px 4px 4px #a0c6f7;
                border: 1px solid #ccc;
            }
        }
        .upload-picture {
            padding: 12px 0px;
        }
    }
    
}
</style>
