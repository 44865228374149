<template>
    <div
        class="region display-f flex-column-jc"
        :class="showTab == 1 ? 'mobileH public-width' : 'pcH public-width_p'"
    >
        <a-spin :spinning="spinning" tip="Loading..." style="height: 100%;">
            <div :class="showTab == 1 ? 'title' : 'title2'">
                <a-radio-group v-show="showTab == 1" :defaultValue="switchTab" button-style="solid" @change="changeSwitchTab" style="width: 88%;">
                    <a-radio-button value="1">儲值</a-radio-button>
                    <!-- <a-radio-button value="3">禮品碼</a-radio-button> -->
                    <a-radio-button value="2">會員中心</a-radio-button>
                    <a-radio-button value="4">遊戲攻略</a-radio-button>
                </a-radio-group>
                <div class="display-f flex-row-jc-e" v-show="showTab == 2">
                    <div class="tab" :class="switchTab == 1 ? 'selectTab' : 'tab2'" @click="switchTab = 1">儲值大廳</div>
                    <!-- <div class="tab" :class="switchTab == 3 ? 'selectTab' : 'tab2'" @click="switchTab = 3">禮品碼</div> -->
                    <div class="tab" :class="switchTab == 2 ? 'selectTab' : 'tab2'" @click="switchTab = 2">會員中心</div>
                    <div class="tab" :class="switchTab == 4 ? 'selectTab' : 'tab2'" @click="switchTab = 4">遊戲攻略</div>
                </div>
            </div>
            <member v-if="switchTab == 2"></member>
            <div class="mobile display-f flex-row-jl" v-if="showTab == 1 && tabValue == '2'">
                <a href="https://www.facebook.com/MonsterinPocket/">聯繫VIP客服專員獲取銀行賬號...</a>
            </div>
            <giftcode v-if="switchTab == 3"></giftcode>
            <recharge-detail v-show="switchTab == 1" :showTab="showTab" ref="childDetail"></recharge-detail>
            <strategy v-show="switchTab == 4" :showTab="showTab"></strategy>
        </a-spin>
        <receive-tips v-if="showReceive" :communication="communication"></receive-tips>
    </div>
</template>

<script>
import { getReqData, getDetailAPI, getCommunicationAPI } from '../api/requests';
import secret from '../api/secret';
import Member from './member.vue';
import giftcode from './giftCode.vue';
import RechargeDetail from './recharge/rechargeDetail.vue';
import strategy from './strategy.vue';
import ReceiveTips from './mailbox/receiveTips.vue';

export default {
    name: 'recharge',
    components: {
        Member,
        giftcode,
        RechargeDetail,
        strategy,
        ReceiveTips
    },
    data () {
        return {
            showTab: '',
            switchTab: this.$route.query.value || '1',
            tabValue: '1',
            isTips: false,
            // 获取用户详情参数
            detailParams: {
                duid: '',
                time: '',
                sign: '',
                uname: '',
                uid: '',
                token: ''
            },
            balance: 0,
            spinning: false,
            showReceive: false,
            communication: ''
        }
    },
    created () {
        if (window.isMobile == 1) {
            this.showTab = 1;
        }
        if (window.isPC == 1) {
            this.showTab = 2;
        }

        if (this.$route.query.value != 4) {
            this.getUserDetail();
        } else {
            if (localStorage.getItem('info')) {
                this.$store.commit('saveUserInfo', JSON.parse(secret.des_decrypt(localStorage.getItem('info'))));
            }
        }
    },
    mounted () {},
    methods: {
        changeSwitchTab (e) {
            this.switchTab = e.target.value;
            if(this.switchTab == 1) {
                this.$refs.childDetail.getWayList();
            }
        },
        changeTab (e) {
            this.isTips = false;
            this.tabValue = e.target.value;
        },


        // 获取用户详情信息
        getUserDetail () {
            if (localStorage.getItem('info')) {
                this.detailParams.token = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token;
                this.detailParams.uid = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
                this.detailParams.uname = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            }
            getDetailAPI (getReqData(this.detailParams)).then((res) => {
                if (res.data.code !== 0) {
                    this.$router.push('/login');
                    this.$store.commit('saveUserInfo', '');
                    localStorage.removeItem('info');
                    localStorage.removeItem('token');
                } else {
                    if (this.showTab == 1) {
                        this.$refs.childDetail.getBalance();
                    }
                    this.$refs.childDetail.init();
                    this.$store.commit('saveUserInfo', res.data.data);
                    localStorage.setItem('info', secret.des_encrypt(JSON.stringify(res.data.data)));
                    localStorage.setItem('token', secret.des_encrypt(res.data.data.token));
                    this.getCommunication(res.data.data);
                }
            }).catch((data) =>{
                console.log('[error]', data);
            })
        },
        getCommunication(data) {
            let temp = {};
            temp.uid = data.uid;
            temp.uname = data.uname;
            temp.token = data.token;
            temp.language = 1;
            temp.pgid = 10;
            getCommunicationAPI(getReqData(temp)).then((res) => {
                if (res.data.code == 0) {
                    this.communication = res.data.data;
                    if (this.communication.email && this.communication.giftcode.email) {
                        this.showReceive = false;
                    } else {
                        this.showReceive = true;
                    }
                }
            }).catch((data) => {
                console.log('[error]', data);
            })
        },
    },
    watch: {
        switchTab: {
            handler: function(newVal, oldVal) {
                if (oldVal && !localStorage.getItem('info')) {
                    this.getUserDetail()
                }
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
    .region {
        box-shadow: 0px 0px 6px 6px #e5e5e5;
        background: #fff;
        // overflow: hidden;
        // overflow-y: scroll;
    }
    .mobileH {
        margin: 48px 0px 12px;
        height: calc(100vh - 48px);
    }
    .pcH {
        margin: 88px 0px 12px;
        height: calc(100vh - 216px);
        min-height: 720px;
        min-width: 1310px;
    }
    .title {
        width: 3.75rem;
        background: #fff;
        padding: 8px;
        // height: 86px;
        // line-height: 60px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 2px solid #dedede;
        /* background: #dedede; */
        .ant-radio-group {
            .ant-radio-button-wrapper {
                width: 33%;
            }
        }
    }

    .title2 {
        min-width: 1310px;
        width: 3.75rem;
        background: #fff;
        padding: 8px;
        // height: 86px;
        // line-height: 60px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 2px solid #dedede;
        /* background: #dedede; */
    }

    .pay {
        width: 100%;
        flex: 1;
        padding-top: 12px;
    }
    .pay .left {
        flex: 1;
        padding: 16px;
        background: #efefef;
    }
    .left .left-item {
        margin-bottom: 24px;
    }
    .left .left-item .way {
        width: 104px;
        height: 56px;
        padding: 4px;
        margin-right: 24px;
        border-radius: 8px;
        border: 1px solid #cccccc;
    }
    .left .left-item .way:hover {
        box-shadow: 0px 0px 4px 4px #a0c6f7;
    }
    .checkWay {
        color: #fff;
        background: #c5d9ec;
        /* background-image: linear-gradient( rgb(11, 250, 218), #4b3feb); */
        box-shadow: 0px 0px 4px 4px #a0c6f7;
    }
    .sel {
        width: 30%;
    }
    .sel label {
        width: 68px;
        font-size: 16px;
        text-align: right;
    }
    .amount {
        /* width: 32%; */
        width: 0.4rem;
        height: 81px;
        margin: 0px 6px 12px 6px;
        /* margin-bottom: 12px; */
        border-radius: 8px;
        background: #ffffff;
        /* font-size: 16px; */
        font-size: 0.0458rem;
        font-weight: bold;
    }
    .amount .goods {
        width: 100%;
        /* border-right: 1px solid #cccccc; */
        padding: 12px;
    }
    .amount .price {
        width: 50%;
        padding: 12px;
        color: #1890ff;
    }
    .currency {
        margin-right: 12px;
    }
    .amount:hover {
        box-shadow: 0px 0px 4px 4px #a0c6f7;
    }
    .checkAmount {
        color: #ffffff;
        background: #1890ff;
        box-shadow: 0px 0px 4px 4px #a0c6f7;
    }
    .checkAmount .price {
        color: #ffffff;
    }
    .pay .right {
        width: 360px;
        padding: 0px 12px;
    }
    .pay .right .tips {
        padding: 12px;
        margin-bottom: 12px;
        background: #efefef;
    }
    .pay .right .total {
        padding: 12px;
        background: #efefef;
    }
    .tab {
        height: 50px;
        padding: 0px 8px;
        font-weight: normal;
        margin: 0px 8px;
    }
    .tab2 {
        border-bottom: 4px solid #dedede;
        color: #666666;
    }
    .selectTab {
        color: #1890ff;
        /* font-weight: bold; */
        border-bottom: 4px solid #1890ff;
    }
    .payWay {
        width: 2.347rem;
        min-width: 820px;
        padding: 6px;
        overflow:  hidden;
        border-radius: 6px;
        overflow-x: scroll;
        box-shadow: inset 18px 0 19px -18px rgb(145, 183, 226), inset -18px 0 18px -13px rgb(145, 183, 226);
    }

    /* 移动端 */
    .mobile {
        padding: 12px 12px 186px;
        overflow: hidden;
        overflow-y: scroll;
        background: #e5e5e5;
    }
    .amount2 {
        width: 1.04rem;
        height: 81px;
        margin: 0px 6px 12px 6px;
        /* margin-bottom: 12px; */
        border-radius: 8px;
        font-size: 14px;
        font-weight: bold;
    }
    .currency2 {
        margin-right: 6px;
    }
    .noCheck {
        background: #ffffff;
    }
    .payNow {
        position: fixed;
        bottom: 0px;
        left: 0px;
        background: #fff;
        width: 100%;
        height: 176px;
        padding-top: 12px;
        border-top: 1px solid #eeeeee;
        box-shadow: 0px 0px 4px 4px #eeeeee;
        text-align: center;
    }
    .mobileTips {
        position: absolute;
        right: 24px;
        top: 28px;
        background: #fff;
        padding: 12px;
        width: 2.4rem;
        box-shadow: 0px 0px 4px 4px #eeeeee;
        z-index: 99;
    }
</style>
