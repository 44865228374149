import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../components/login.vue';
import Recharge from '../components/recharge.vue';
import PayResult from '../components/payResult.vue';
import Agreement from '../components/agreement.vue';
import gameStrategy from '../components/gameStrategy.vue';
import addStrategy from '../components/addStrategy.vue';
import strategyDetail from '../components/strategyDetail.vue';

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/about',
    name: 'About',
    meta: { showNav: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/journalism',
    name: 'Journalism',
    meta: { showNav: false },
    component: () => import(/* webpackChunkName: "about" */ '../components/journalism.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { showNav: false }
  },
  {
    path: '/',
    name: 'Recharge',
    component: Recharge,
    meta: { showNav: true }
  },
  {
    path: '/payResult',
    name: 'payResult',
    component: PayResult,
    meta: { showNav: true }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: Agreement,
    meta: { showNav: true }
  },
  {
    path: '/gameStrategy',
    name: 'gamestrategy',
    component: gameStrategy,
    meta: { showNav: true }
  },
  {
    path: '/addStrategy',
    name: 'addstrategy',
    component: addStrategy,
    meta: { showNav: true }
  },
  {
    path: '/strategyDetail',
    name: 'strategyDetail',
    component: strategyDetail,
    meta: { showNav: true }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // linkActiveClass: 'active',
})

// router.beforeEach((to, from, next) => {
//   console.log('【to】', to);
//   console.log('【from】', from);
//   if (to.path == '/') {
//     if (localStorage.getItem('token')) {
//       next()
//     } else {
//       next({"path":"/login", query:{"topath": to.path}})
//     }
//   } else {
//     next()
//   }
  
// })

export default router
