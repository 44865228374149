// /**
//  * axios封装
//  * 请求拦截、响应拦截、错误统一处理
//  */
import axios from 'axios';

import md5 from 'md5';

// axios.defaults.baseURL = '/api';
axios.defaults.baseURL = 'http://apis.llmaitengff.com';

axios.defaults.timeout = 180000; // 请求超时时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';

export function ceshi(params) {
    return axios({
        url: '/init/index/',
        method: 'post',
        data: params
    })
}

export function integration(obj1) {
    const result = {};
  
    Object.keys(obj1).forEach((key1) => {
      if (Array.isArray(obj1[key1])) {
        result[key1] = obj1[key1][0];
      } else {
        result[key1] = obj1[key1];
      }
    })
    return result
  }

// 定义排序方法
function sortObjByKey(obj) {
    // 获取原对象集的key值赋给keys
    const keys = Object.keys(obj).sort()
    // 定义一个新的对象newObj，遍历keys并将每个key值添加到newObj，根据key匹配原对象集obj对应的key值
    let newObj = {}
    for (let i = 0; i < keys.length; i++) {
        const index = keys[i]
        newObj[index] = obj[index]
    }
    // 返回一个新的对象newObj（与原对象的区别：值为0或undefined的键值对，值变为空字符串）
    return newObj
}

function getParams(data) {
    data.time = parseInt(Date.parse(new Date()) / 1000).toString()
    if (!data.duid) {
        data.duid = localStorage.getItem('browserId') || '';
    }
    const keys = Object.keys(data).sort()
    let urlParams = '?';
    for (let i = 0; i < keys.length; i++) {
        const index = keys[i]
        if (i == keys.length) {
            urlParams = urlParams + index + '=' + encodeURIComponent(data[index])
        } else {
            urlParams = urlParams + index + '=' + encodeURIComponent(data[index]) + '&'
        }
    }
    return urlParams
}

export function getReqData(data) {
    // 获取时间戳并转为字符串
    data.time = parseInt(Date.parse(new Date()) / 1000).toString()
    if (!data.duid) {
        data.duid = localStorage.getItem('browserId') || '';
    }
    let reqData = sortObjByKey(data)
    // reqData.duid = localStorage.getItem('browserId');
    // 遍历reqData，去除sign用新变量tmpStr接
    let tmpStr = ''
    for (const key in reqData) {
        let tmp = ''
        if (key !== 'sign') {
            if (reqData[key]) {
                tmp = reqData[key]
            } else {
                reqData[key] = ''
            }
            tmpStr += key + '=' + tmp + '&'
        }
    }
    tmpStr = tmpStr.substr(0, tmpStr.length - 1) + 'haiwaidccd^$#scz*sx!sczsfv';
    reqData.sign = md5(tmpStr);
    return reqData
    // return getFormData(reqData);
}

export function getListCodeReqData(data, reqType) {
    // 获取时间戳并转为字符串
    data.time = parseInt(Date.parse(new Date()) / 1000).toString();
    data.duid = localStorage.getItem('browserId');
    let reqData = sortObjByKey(data)
    // 遍历reqData，去除sign用新变量tmpStr接
    let tmpStr = ''
    if (reqType == 2) {
        tmpStr = data.uid + data.list_id + data.time + 'haiwaidccd^$#scz*sx!sczsfv' + data.uname;
    }
    if (reqType == 1) {
        tmpStr = data.uid + data.time + 'haiwaidccd^$#scz*sx!sczsfv' + data.uname;
    }
    reqData.sign = md5(tmpStr);
    return reqData
    // return getFormData(reqData);
}

export function getSign(data) {
    // 获取时间戳并转为字符串
    let reqData = sortObjByKey(data)
    // 遍历reqData，去除sign用新变量tmpStr接
    let tmpStr = ''
    for (const key in reqData) {
        let tmp = ''
        if (key !== 'sign') {
            if (reqData[key]) {
                tmp = reqData[key]
            } else {
                reqData[key] = ''
            }
            tmpStr += key + '=' + tmp + '&'
        }
    }
    tmpStr = tmpStr.substr(0, tmpStr.length - 1) + 'JjkHknJBGjask20221201';
    const sign = md5.digest().toHex(tmpStr);
    return sign
    // return getFormData(reqData);
}

export function getRecallReq(data) {
    // 获取时间戳并转为字符串
    data.time = parseInt(Date.parse(new Date()) / 1000).toString()
    if (!data.duid) {
        data.duid = localStorage.getItem('browserId');
    }
    let reqData = sortObjByKey(data)
    // reqData.duid = localStorage.getItem('browserId');
    // 遍历reqData，去除sign用新变量tmpStr接
    let tmpStr = ''
    for (const key in reqData) {
        let tmp = ''
        if (key !== 'sign' && key !== 'token') {
            if (reqData[key]) {
                tmp = reqData[key]
            } else {
                reqData[key] = ''
            }
            tmpStr += key + '=' + tmp + '&'
        }
    }
    tmpStr = tmpStr.substr(0, tmpStr.length - 1) + 'Jp8vQOYckhhta20221121';
    reqData.sign = md5(tmpStr);
    return reqData
    // return getFormData(reqData);
}

export function getReqDataNoToken(data) {
    // 获取时间戳并转为字符串
    data.time = parseInt(Date.parse(new Date()) / 1000).toString()
    if (!data.duid) {
        data.duid = localStorage.getItem('browserId');
    }
    let reqData = sortObjByKey(data);
    // 遍历reqData，去除sign用新变量tmpStr接
    let tmpStr = ''
    for (const key in reqData) {
        let tmp = ''
        if (key !== 'sign' && key !== 'token') {
            if (reqData[key]) {
                tmp = reqData[key]
            } else {
                reqData[key] = ''
            }
            tmpStr += key + '=' + tmp + '&'
        }
    }
    tmpStr = tmpStr.substr(0, tmpStr.length - 1) + 'haiwaidccd^$#scz*sx!sczsfv';
    reqData.sign = md5(tmpStr);
    return reqData
    // return getFormData(reqData);
}

// 将提交参数转化为FormData格式
function getFormData(data) {
    let params = new FormData()
    Object.keys(data).forEach((key) => {
        params.append(key, data[key])
    })
    return params
}

// 1、登录请求
export function loginAPI(params) {
    return axios({
        url: '/user/login/',
        method: 'post',
        data: getFormData(params),
    })
}

// 2、用户详情
export function getDetailAPI(params) {
    return axios({
        url: '/user/detail/',
        method: 'post',
        data: getFormData(params),
    })
}

// 3、获取用户余额
export function getBalanceAPI(params) {
    return axios({
        url: '/user/getBillingAccount/' + getParams(params),
        method: 'get'
    })
}

// 4、账号验证（预留，判断账号是否存在）
export function judgeAccountAPI(params) {
    return axios({
        url: '/user/check/?' + getParams(params),
        method: 'get'
    })
}

// 5、退出登录
export function loginoutAPI(params) {
    return axios({
        url: '/user/loginout/',
        method: 'post',
        data: getFormData(params),
    })
}

// 6、获取充值列表
export function getWayListAPI(params) {
    return axios({
        url: '/pay/waylist/' + getParams(params),
        method: 'get'
    })
}

// 7、下单
export function createOrderAPI(params) {
    return axios({
        url: '/pay/create/',
        method: 'post',
        data: getFormData(params)
    })
}

// 8、支付网关
export function payGateAPI(params) {
    return axios({
        url: '/pay/paygate/',
        method: 'post',
        data: getFormData(params),
    })
}

// 9、订单状态查询
export function getOrderStatusAPI(params) {
    return axios({
        url: '/payment/checkPayStatus/' + getParams(params),
        method: 'get'
    })
}


// 10、获取所有区服列表
export function getAreaAPI() {
    return axios({
        url: '/rebate/getarea/',
        method: 'get'
    })
}

// 11、获取单账号对应区服列表
export function getAreaRolesAPI(params) {
    return axios({
        url: '/rebate/getarearoles/' + getParams(params),
        method: 'get'
    })
}

// 12、获取区服对应角色
export function getRoleAPI(params) {
    return axios({
        url: '/rebate/getrole/' + getParams(params),
        method: 'get'
    })
}

// 13、礼包码列表
export function getGiftCodeListAPI(params) {
    return axios({
        url: '/giftcode/listcode/' + getParams(params),
        method: 'get'
    })
}

// 14、领取礼包
export function getGiftCodeAPI(params) {
    return axios({
        url: '/giftcode/getcode/' + getParams(params),
        method: 'get'
    })
}

// 15、返利充值档位
export function getRebateLevelAPI(params) {
    return axios({
        url: '/rebate/getRebateLevel/' + getParams(params),
        method: 'get'
    })
}

// 16、返利列表
export function getRebateListAPI(params) {
    return axios({
        url: '/rebate/getRebate/' + getParams(params),
        method: 'get'
    })
}

// 17、返利充值下单
export function rebateCreateOrderAPI(params) {
    return axios({
        url: '/rebate/create/',
        method: 'post',
        data: getFormData(params)
    })
}

// 18、返利充值支付网关
export function rebatePayGateAPI(params) {
    return axios({
        url: '/rebate/paygate/',
        method: 'post',
        data: getFormData(params),
    })
}

// 19、获取备注
export function getRemarkAPI() {
    return axios({
        url: '/rebate/getRemark/',
        method: 'get'
    })
}

// 20、获取储值记录
export function getAllPaymentAPI(params) {
    return axios({
        url: '/rebate/getAllPayment/' + getParams(params),
        method: 'get'
    })
}

// 21、获取用户邮箱接口
export function getEmailAPI(params) {
    return axios({
        url: '/gwreset/getEmail/' + getParams(params),
        method: 'get'
    })
}

// 22、获取验证码
export function sendAuthCodeAPI(params) {
    return axios({
        url: '/gwreset/sendAuthCode/' + getParams(params),
        method: 'get'
    })
}

// 23、邮箱绑定
export function bindingEmailAPI(params) {
    return axios({
        url: '/gwreset/bindingEmail/' + getParams(params),
        method: 'get'
    })
}

// 24、修改密码
export function changePwdAPI(params) {
    return axios({
        url: '/gwreset/changePwd/',
        method: 'post',
        data: getFormData(params),
    })
}

// 25、上传图片
export function upFilePictureAPI(params) {
    return axios({
        url: '/walkthrough/_upFilePicture',
        method: 'post',
        data: getFormData(params),
    })
}

// 26、新增攻略文章
export function addArticleAPI(params) {
    return axios({
        url: '/walkthrough/_addarticle',
        method: 'post',
        data: getFormData(params),
    })
}

// 27、文章列表
export function getArticleListAPI(params) {
    return axios({
        url: '/walkthrough/_queryList' + getParams(params),
        method: 'get'
    })
}

// 28、文章详情
export function getArticleDetailAPI(params) {
    return axios({
        url: '/walkthrough/_queryArticle' + getParams(params),
        method: 'get'
    })
}

// 29、点赞/踩上报
export function getPVCountAPI(params) {
    return axios({
        url: '/walkthrough/_pvCount' + getParams(params),
        method: 'get'
    })
}

// 30、是否点赞/点踩判断
export function getJudgeLikeAPI(params) {
    return axios({
        url: '/walkthrough/getlikeone' + getParams(params),
        method: 'get'
    })
}

// 31、获取主评论列表接口
export function getReplyListAPI(params) {
    return axios({
        url: '/reply/lists/' + getParams(params),
        method: 'get'
    })
}

// 32、
export function getReplyMoreListAPI(params) {
    return axios({
        url: '/reply/moreList/' + getParams(params),
        method: 'get'
    })
}

// 33、用户评论接口
export function addReplyAPI(params) {
    return axios({
        url: '/reply/comment/',
        method: 'post',
        data: getFormData(params),
    })
}

// 34、删除评论接口
export function deleReplyAPI(params) {
    return axios({
        url: '/reply/delcomment/',
        method: 'post',
        data: getFormData(params),
    })
}

// 35、评论点赞状态变动接口
export function upLikeAPI(params) {
    return axios({
        url: '/reply/upLike/',
        method: 'post',
        data: getFormData(params),
    })
}

// 36、评论踩状态变动接口
export function upCombatAPI(params) {
    return axios({
        url: '/reply/upCombat/',
        method: 'post',
        data: getFormData(params),
    })
}

// 37、评论举报API
export function reportAPI(params) {
    return axios({
        url: '/reply/report',
        method: 'post',
        data: getFormData(params),
    })
}

// 38、获取用户通信信息接口
export function getCommunicationAPI(params) {
    return axios({
        url: '/user/getCommunication/',
        method: 'post',
        data: getFormData(params),
    })
}

// 39、设置用户通信信息接口
export function saveCommunicationAPI(params) {
    return axios({
        url: '/user/setCommunication/',
        method: 'post',
        data: getFormData(params),
    })
}

// 40、用户token登录接口
export function tokenLoginAPI(params) {
    return axios({
        url: '/user/tokenlogin/',
        method: 'post',
        data: getFormData(params),
    })
}

// 41、IP信息接口
export function IPInfoAPI() {
    return axios({
        url: '/home/ip/',
        method: 'post',
        data: '',
    })
}

// 42、充值排行榜
export function getPayRankAPI(params) {
    return axios({
        url: '/pay/rank/' + getParams(params),
        method: 'get'
    })
}

// 43、用户领取礼包奖励接口
export function receiveGiftAPI(params) {
    return axios({
        url: '/activity/receivegift/',
        method: 'post',
        data: getFormData(params),
    })
}

// 44、获取充值列表(新接口)
export function newWayListAPI(params) {
    return axios({
        url: '/pay/waylistnew/' + getParams(params),
        method: 'get'
    })
}

// 45、充值档位新接口
export function getPayNapeAPI(params) {
    return axios({
        url: '/pay/products/' + getParams(params),
        method: 'get'
    })
}

// 46、邮箱下发验证码接口（新）
export function getVerifyCodeAPI(params) {
    return axios ({
        url: '/email/code/' + getParams(params),
        method: 'get'
    })
}

// 48、邮箱下发验证码接口（新）
export function getUpBindCodeAPI(params) {
    return axios ({
        url: '/user/upbindemail/' + getParams(params),
        method: 'get'
    })
}
