import Fingerprint2 from 'fingerprintjs2'

import { IPInfoAPI } from './requests';

function createFingerprint () {
    // 选择哪些信息作为浏览器指纹生成的依据
    const options = {
        fonts: {
        extendedJsFonts: true,
        },
        excludes: {
        language: true,
        colorDepth: true,
        deviceMemory: true,
        pixelRatio: true,
        availableScreenResolution: true,
        timezoneOffset: true,
        timezone: true,
        sessionStorage: true,
        localStorage: true,
        indexedDb: true,
        addBehavior: true,
        openDatabase: true,
        cpuClass: true,
        doNotTrack: true,
        plugins: true,
        canvas: true,
        webglVendorAndRenderer: true,
        adBlock: true,
        hasLiedLanguages: true,
        hasLiedResolution: true,
        hasLiedOs: true,
        hasLiedBrowser: true,
        touchSupport: true,
        audio: false,
        enumerateDevices: true,
        hardwareConcurrency: true,
        },
        

    }
    // 浏览器指纹
    Fingerprint2.get(options, (components) => {

        IPInfoAPI().then((res) => {
            if (res.data.code == 0) {
                components.push({
                    key: 'ip',
                    value: res.data.data.cip
                })
                // 参数只有回调函数或者options为{}时，默认浏览器指纹依据所有配置信息进行生成
                const values = components.map((component) => component.value) // 配置的值的数组
                const murmur = Fingerprint2.x64hash128(values.join(''), 31) // 生成浏览器指纹
                if (!localStorage.getItem('browserId'))
                localStorage.setItem('browserId', murmur) // 存储浏览器指纹，在项目中用于校验用户身份和埋点
            } else {
                components.push({
                    key: 'ip',
                    value: ''
                })
                
                // 参数只有回调函数或者options为{}时，默认浏览器指纹依据所有配置信息进行生成
                const values = components.map((component) => component.value) // 配置的值的数组
                const murmur = Fingerprint2.x64hash128(values.join(''), 31) // 生成浏览器指纹
                if (!localStorage.getItem('browserId'))
                localStorage.setItem('browserId', murmur) // 存储浏览器指纹，在项目中用于校验用户身份和埋点
            }
        }).catch((data) => {
            console.log(data);
        })
    })
}

export default createFingerprint
