<template>
    <div class="top display-f flex-row-jc" :class="isMobile ? 'top-height-m' : 'top-height-p'" >
        <div class="display-f flex-row-jl-c " :class="isMobile ? 'public-width' : 'public-width_p'">
            <div><img src="../assets/logo.png" alt="" :class="isMobile ? 'logo-height-m' : 'logo-height-p'"></div>
            <div class="right-content display-f">
                <div class="seach-box">
                    <!-- <div class="seach display-f flex-row-jl-c">
                        <input type="text"><button @click="search">蒐索</button>
                    </div> -->
                    <span v-if="!$store.state.userInfo" @click="goStrategy" style="color: #ffffff;margin-left: 12px;font-size: 14px;">遊戲攻略</span>
                </div>
                <div class="login display-f flex-row-jc-c" :class="isMobile ? '' : 'linshi'" v-if="!$store.state.userInfo">
                    <span @click="$router.push('/login')">登入</span>
                    <!-- <span style="margin: 0px 8px;">|</span>
                    <span>註冊</span> -->
                </div>
                <div v-if="$store.state.userInfo" class="login display-f flex-row-jc-c" :class="isMobile ? '' : 'linshi'">
                    <!-- <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" style="width: 28px;height: 28px;" /> -->
                    <span style="flex: 1; text-align: right; overflow: hidden; text-overflow:ellipsis; white-space: nowrap;">{{ $store.state.userInfo.uname }}</span>
                    <span style="margin: 0px 4px;">|</span>
                    <span @click="signOut()" style="width: 40px;"><a href="">登出</a></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ceshi, getReqData, loginoutAPI } from '../api/requests'
export default {
    name: 'top',
    data () {
        return {
            isMobile: 0,
            isLogin: false,
            userName: '',
            signOutParams: {
                duid: '',
                time: '',
                sign: ''
            }
        }
    },
    computed: {
        count () {
            return this.$store.state.userInfo
        }
    },
    created () {
        if (window.isMobile == 1) {
            this.isMobile = 1;
        }
        if (window.isPC == 1) {
            this.isMobile = 0;
        }
        // if (localStorage.getItem('info')) {
        //     this.$store.commit('saveUserInfo', JSON.parse(localStorage.getItem('info')));
        //     this.isLogin = true;
        // }
    },
    methods: {
        search () {
            ceshi().then(res => {
                this.$message.warning(res.data.msg);
            })
        },
        signOut () {
            loginoutAPI(getReqData(this.signOutParams)).then((res) => {
                if (res.data.code == 0) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('info')
                    this.$store.commit('saveUserInfo', '')
                    this.isLogin = false;
                    this.$message.success(res.data.msg);
                    window.location.reload()
                } else {
                    this.$message.warning(res.data.msg);
                }
            })
            // this.$router.push('/login')
        },

        goStrategy() {
            this.$router.push({ path: '/', query: { value: 4 } })
        }
    }
}
</script>

<style scoped>
    .top {
        width: 100%;
        min-width: 1310px;
        /* background: #6fbef7; */
        background: linear-gradient(to bottom right, rgba(75,63,235, 0.4) , rgb(11, 250, 218, 0.4));
        background: -webkit-linear-gradient(to bottom right, rgba(75,63,235, 0.4) , rgb(11, 250, 218));
        border-bottom: 1px solid #cdcdcd;
        margin-bottom: 8px;
        position: fixed;
        top: 0px;
        z-index: 999;
    }
    .top-height-m {
        height: 48px;
    }
    .top-height-p {
        height: 80px;
    }
    .logo-height-m {
        height: 40px;
    }
    .logo-height-p {
        height: 56px;
    }
    .right-content {
        flex: 1;
        height: 80%;
        padding-bottom: 8px;
        /* width: 640px; */
    }
    .seach-box {
        flex: 1;
        display: flex;
        align-items: flex-end;
        font-size: 16px;
    }
    .login {
        width: 1.6rem;
        font-size: 14px;
        color: #fff;
    }
    .linshi {
        min-width: 550px;
    }
    .seach {
        width: 360px;
        border-radius: 6px;
        /* background: rgb(255, 145, 0); */
        overflow: hidden;
    }
    .seach button {
        border: none;
        width: 72px;
        height: 32px;
    }
    .seach button:hover {
        background: rgb(255, 145, 0);
    }
    .seach input {
        flex: 1;
        border: none;
        height: 32px;
        padding: 0px 4px 0px 8px;
        border-radius: 6px 0px 0px 6px;
    }
    .seach input:focus {
        outline:none;
        border: none;
        /* border: 1px solid rgb(57, 138, 204); */
    }
    .logout {
        color: #333;
        width: 60px;
        height: 32px;
        background: #ddd;
        border: none;
        border-radius: 6px;
    }
</style>
