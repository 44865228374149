<template>
    <div style="width: 100%; height: 100%; overflow: hidden;">
        <div v-if="PCDom">
            <a-tabs default-active-key="personalCenter" tab-position="left" size="large" style="height: 100%;" :activeKey="activeKeym" @change="changeTab">
                <a-tab-pane key="personalCenter" tab="個人中心">
                    <div class="vip-box vip-PC">
                        <div>
                            <span>帳號：</span>
                            <span style="color: #1890ff;">{{ userInfo.uname }}</span>
                        </div>
                        <div class="display-f flex-row-js-c" style="width: 460px; padding: 10px 0px; border-bottom: 1px solid rgb(255 255 255); border-top: 1px solid rgb(255 255 255);">
                            <span>已綁定郵箱：<i style="color: rgb(24 144 255);">{{ communication.email }}</i></span>
                            <span @click="goChangeEmail" style="color: rgb(24 144 255);">修改</span>
                        </div>
                        <div class="display-f flex-row-js-c" style="width: 460px; padding: 10px 0px; border-bottom: 1px solid rgb(255 255 255); border-top: 1px solid rgb(255 255 255);">
                            <span>綁定郵箱獎勵：</span>
                            <a-button type="primary" @click="receiveConfig('email')">{{ communication.giftcode.email ? '重新發送' : '領取'}}</a-button>
                        </div>
                        <div v-if="communication.paymoney > 0" class="vip-box2">
                            <div class="vip-text">
                                <span class="line" style="font-size: 15px; color: orange;">恭喜您成為超級vip用戶!</span>
                                <span class="line">特權1.vip專屬客服添加成功，即可領取認證大禮包；</span>
                                <span class="line">特權2.一對一專屬服務通道，問題優先處理；</span>
                                <span class="line">特權3.每月vip專屬禮包，享受專屬vip福利；</span>
                                <span>更多特權福利陸續上線</span>
                            </div>
                            <div class="display-f flex-row-js-c" style="width: 460px; margin-top: 10px; padding: 10px 0px; border-bottom: 1px solid rgb(238 238 238); border-top: 1px solid rgb(238 238 238);">
                                <span>VIP等級:</span>
                                <span style="font-size: 16px; font-weight: bold; color: rgb(255 0 0);">{{ communication.vipName }}</span>
                            </div>
                            <div class="display-f flex-row-js-c" style="width: 460px; padding: 10px 0px; border-bottom: 1px solid rgb(238 238 238); border-top: 1px solid rgb(238 238 238);">
                                <span>月度VIP禮包:</span>
                                <a-button v-if="communication.is_first_vip_gift" @click="lqTips">{{ '發送' }}</a-button>
                                <a-button
                                    v-else
                                    type="primary"
                                    @click="vipGif('monthvip')"
                                    :disabled="timing"
                                >{{ timing ? count + 'S冷卻' : '重新發送' }}</a-button>
                            </div>
                            <div class="vip-contact">
                                <span>聯絡方式：</span>
                                <a-radio-group name="radioGroup" :default-value="communication.link_type ? communication.link_type : '1'" @change="checkType">
                                    <a-radio :value="'1'">LINE</a-radio>
                                    <a-radio :value="'2'">Telegram</a-radio>
                                    <a-radio :value="'3'">WhatsApp</a-radio>
                                    <a-radio :value="'4'">Facebook</a-radio>
                                </a-radio-group>
                                <div class="email-item display-f flex-row-jl-c" style="width: 420px; margin-top: 12px;">
                                    <label for="email" style="flex: 1; text-align: center;"><i style="color: red;">*</i>&nbsp;ID：</label>
                                    <a-input id="email" :value="communication.link_id" @change="(e) => { communication.link_id = e.target.value; }" placeholder="請輸入您的ID" style="width: 82%;" />
                                </div>
                                <i style="font-size: 12px; color: rgb(255 0 0);">Tips:：請大大留下勾選的通訊軟體ID，不是遊戲內ID哦~</i>
                                <span style="display: block; margin: 12px 0px; font-size: 12px; color: rgb(255 0 0);">vip專屬聯絡通道：vip專屬客服會主動添加您，添加成功後，可獲得專屬禮包一份噢！</span>
                                <a-button type="primary" style="width: 140px; margin-left: 48px; margin: 12px 48px;" @click="saveCommunication">儲存</a-button>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="order" tab="儲值記錄">
                    <a-spin :spinning="spinning" tip="Loading..." style="height: 100%;">
                        <div style="padding: 12px 0px;">
                            <span>
                                下單時間：
                                <a-range-picker @change="onChange" :placeholder="['開始時間', '結束時間']" />
                            </span>
                            <span style="margin-left: 24px;">
                                儲值總金額：
                                <span style="color: #f58220;">{{ tableData.SUMMONEY }}</span>
                            </span>
                        </div>
                        <a-table
                            :columns="columnsP"
                            :data-source="tableData.data"
                            size="middle"
                            :row-key="(record, index) => { return index }"
                        />
                    </a-spin>
                </a-tab-pane>
                <a-tab-pane key="blance" tab="平臺幣餘額">
                    <div style="font-size: 18px; padding: 8px 0px;">
                        <span>您的平臺幣餘額为：</span>
                        <span style="font-size: 20px; font-weight: bold; color: #1890ff;">$&nbsp;{{ balance }}</span>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="changePass" tab="更改密碼">
                    <div style="font-size: 16px; padding-top: 12px;">
                        <span style="margin: 0px 0px 8px; display: block;"><MailOutlined style="color: #ff66cc;" />&nbsp;&nbsp;郵箱驗證更改：</span>
                        <div v-if="!isEmail" class="email-box">
                            <span v-if="!changeBingding" style="font-size: 12px; color: red;">您好！首次使用郵箱驗證功能需要先綁定您的郵箱</span>
                            <span v-if="changeBingding" style="font-size: 12px; color: #cccccc; margin-bottom: 8px; display: block;">{{ '綁定郵箱：' + communication.email }}</span>
                            <div v-if="changeBingding" class="email-item display-f flex-row-jl-c">
                                <label for="code"  style="flex: 1;"><i>*</i>&nbsp;旧郵箱驗證：</label>
                                <a-input-group id="code" compact  style="width: 72%;">
                                    <a-input v-model:value="upParams.bdoCode" placeholder="請輸入驗證碼" style="width: 66%" />
                                    <a-button type="primary" @click="getAuthCode(9)" :disabled="timing2">{{ timing2 ? count2 + 'S' : '獲取驗證碼'}}</a-button>
                                </a-input-group>
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="email" style="flex: 1;"><i>*</i>&nbsp;{{ changeBingding ? '新郵箱：' : '郵箱：'}}</label>
                                <a-input id="email" v-if="!changeBingding" @change="(e) => { errorText.email.eNumber = ''; bingdingParams.EMAIL = e.target.value }" placeholder="請輸入您的郵箱" style="width: 82%;" />
                                <a-input id="emailc" v-if="changeBingding" v-model:value="upParams.email" placeholder="請輸入您的郵箱" style="width: 82%;" />
                                <span class="tips">{{ errorText.email.eNumber }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="code"  style="flex: 1;"><i>*</i>&nbsp;驗證碼：</label>
                                <a-input-group id="code" compact  style="width: 82%;">
                                    <a-input v-model:value="bingdingParams.AuthCode" @change="() => { errorText.email.eCodeTips = '' }" placeholder="請輸入驗證碼" style="width: 70%" />
                                    <a-button v-if="!changeBingding" type="primary" @click="sendAuthCode(0)">{{ errorText.email.eCode ? '重新獲取' : '獲取驗證碼'}}</a-button>
                                    <a-button v-if="changeBingding" type="primary" @click="getAuthCode(4)" :disabled="timing3">{{ timing3 ? count3 + 'S' : '獲取驗證碼'}}</a-button>
                                </a-input-group>
                                <span class="tips">{{ errorText.email.eCodeTips }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jc-c">
                                <a-button v-if="!changeBingding" type="primary" :loading="bindLoading" @click="bindingEmail(0)" style="width: 60%;">確認綁定</a-button>
                                <a-button v-if="changeBingding" @click="() => { changeBingding = false; isEmail = true }" style="width: 40%; margin-right: 8px;">返回</a-button>
                                <a-button v-if="changeBingding" type="primary" :loading="bindLoading" @click="getUpBindCode()" style="width: 40%; margin-left: 8px;">確認綁定</a-button>
                            </div>
                        </div>
                        <div v-else class="email-box">
                            <!-- <div class="email-item display-f flex-row-jl-c">
                                <label for="email" style="flex: 1;"><i>*</i>&nbsp;原密碼：</label>
                                <a-input-password id="email" placeholder="請輸入原密碼" style="width: 78%;" />
                            </div> -->
                            
                            <span style="font-size: 12px; color: #cccccc; margin-bottom: 8px; display: block;">{{ '綁定郵箱：' + communication.email }} <a @click="changeEmail">修改...</a></span>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="pwd" style="flex: 1;"><i>*</i>&nbsp;新密碼：</label>
                                <a-input-password id="pwd" v-model:value="newPwd" @change="() => { errorText.accounts.numberTips = '' }" placeholder="請輸入新密碼" style="width: 78%;" />
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="newPwd" style="flex: 1;"><i>*</i>&nbsp;確認密碼：</label>
                                <a-input-password id="newPwd" v-model:value="confirmPwd.UPWD" @change="() => { errorText.accounts.numberTips = '' }" placeholder="請輸入新密碼" style="width: 78%;" />
                                <span class="tips">{{ errorText.accounts.numberTips }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="Pcode"  style="flex: 1;"><i>*</i>&nbsp;驗證碼：</label>
                                <a-input-group id="Pcode" compact  style="width: 78%;">
                                    <a-input v-model:value="confirmPwd.AuthCode" @change="() => { errorText.accounts.codeTips = '' }" placeholder="請輸入驗證碼" style="width: 69%" />
                                    <a-button type="primary" @click="sendAuthCode(1)">獲取驗證碼</a-button>
                                </a-input-group>
                                <span class="tips">{{ errorText.accounts.codeTips }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jc-c">
                                <a-button type="primary" :loading="pwdLoading" @click="changePwd" style="width: 60%;">確認更改</a-button>
                            </div>
                        </div>
                    </div>
                    <a href="https://www.facebook.com/MonsterinPocket/">在線客服更改密碼...</a>
                </a-tab-pane>
                <!-- <a-tab-pane key="giftCode" tab="我的礼品码">
                    <div class="display-f flex-column-jl-c" style="font-size: 14px; padding: 8px 0px; color: #444444; font-weight: bold;">
                        <a-row style="width: 100%; padding: 8px 0px; border-bottom: 1px solid #dddddd;" v-for="i in 10" :key="i">
                            <a-col :span="6">
                                礼品码名称：<span class="fontStyle">iOS新手礼包</span>
                            </a-col>
                            <a-col :span="10">
                                礼品码具体：<span class="fontStyle">你这里要显示什么内容，文档没看懂。</span>
                            </a-col>
                            <a-col :span="4">
                                是否使用：<span class="fontStyle">否</span>
                            </a-col>
                            <a-col :span="4">
                                有效期：<span class="fontStyle">2021.11.11.11.11.11</span>
                            </a-col>
                        </a-row>
                    </div>
                </a-tab-pane> -->
                <a-tab-pane key="3" tab="聯繫客服">
                    <div style="font-size: 18px; padding: 8px 0px;">
                        <a href="https://www.facebook.com/MonsterinPocket/">聯繫VIP客服專員...</a>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
        <div v-if="mobileDom" style="width: 3.75rem;">
            <a-tabs default-active-key="personalCenter" tab-position="left" size="small" tabPosition="top" :tabBarGutter="0" :style="{ height: '100%' }" :activeKey="activeKeym" @change="changeTab" @prevClick="callback" @nextClick="callback">
                <a-tab-pane key="personalCenter" tab="個人中心">
                    <div class="vip-box">
                        <div>
                            <span>帳號：</span>
                            <span style="color: #1890ff;">{{ userInfo.uname }}</span>
                        </div>
                        <div class="display-f flex-row-js-c" style="padding: 10px 0px; border-bottom: 1px solid rgb(255 255 255); border-top: 1px solid rgb(255 255 255);">
                            <span>已綁定郵箱：<i style="color: rgb(24 144 255);">{{ communication.email }}</i></span>
                            <span @click="goChangeEmail" style="color: rgb(24 144 255);">修改</span>
                        </div>
                        <div class="display-f flex-row-js-c" style="padding: 10px 0px; border-bottom: 1px solid rgb(255 255 255); border-top: 1px solid rgb(255 255 255);">
                            <span>綁定郵箱獎勵：</span>
                            <a-button type="primary" @click="receiveConfig('email')">{{ communication.giftcode.email ? '重新發送' : '領取'}}</a-button>
                        </div>
                        <div v-if="communication.paymoney > 0" class="vip-box2">
                            <div class="vip-text">
                                <span class="line" style="font-size: 15px; color: orange;">恭喜您成為超級vip用戶!</span>
                                <span class="line">特權1.vip專屬客服添加成功，即可領取認證大禮包；</span>
                                <span class="line">特權2.一對一專屬服務通道，問題優先處理；</span>
                                <span class="line">特權3.每月vip專屬禮包，享受專屬vip福利；</span>
                                <span>更多特權福利陸續上線</span>
                            </div>
                            <div class="display-f flex-row-js-c" style="margin-top: 10px; padding: 10px 0px; border-bottom: 1px solid rgb(238 238 238); border-top: 1px solid rgb(238 238 238);">
                                <span>VIP等級:</span>
                                <span style="font-size: 16px; font-weight: bold; color: rgb(255 0 0);">{{ communication.vipName }}</span>
                            </div>
                            <div class="display-f flex-row-js-c" style="padding: 10px 0px; border-bottom: 1px solid rgb(238 238 238); border-top: 1px solid rgb(238 238 238);">
                                <span>月度VIP禮包:</span>
                                <a-button v-if="communication.is_first_vip_gift" @click="lqTips">{{ '發送' }}</a-button>
                                <a-button
                                    v-else
                                    type="primary"
                                    @click="vipGif('monthvip')"
                                    :disabled="timing"
                                >{{ timing ? count + 'S冷卻' : '重新發送' }}</a-button>
                            </div>
                            <div class="vip-contact_m">
                                <span>聯絡方式：</span>
                                <a-radio-group name="radioGroup" :default-value="communication.link_type ? communication.link_type : '1'" @change="checkType">
                                    <a-radio :value="'1'">LINE</a-radio>
                                    <a-radio :value="'2'">Telegram</a-radio>
                                    <a-radio :value="'3'">WhatsApp</a-radio>
                                    <a-radio :value="'4'">Facebook</a-radio>
                                </a-radio-group>
                                <div class="email-item display-f flex-row-jl-c" style="margin-top: 12px;">
                                    <label for="email" style="flex: 1; text-align: center;"><i style="color: red;">*</i>&nbsp;ID：</label>
                                    <a-input id="email" :value="communication.link_id" @change="(e) => { communication.link_id = e.target.value; }" placeholder="請輸入您的ID" style="width: 82%;" />
                                </div>
                                <i style="font-size: 12px; color: rgb(255 0 0);">Tips:：請大大留下勾選的通訊軟體ID，不是遊戲內ID哦~</i>
                                <span style="display: block; margin: 12px 0px; font-size: 12px; color: rgb(255 0 0);">vip專屬聯絡通道：vip專屬客服會主動添加您，添加成功後，可獲得專屬禮包一份噢！</span>
                                <a-button type="primary" style="width: 140px; margin-left: 48px; margin: 12px 48px;" @click="saveCommunication">儲存</a-button>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="order" tab="儲值記錄">
                    <div class="" style="width: 100%; padding: 8px; background: #efefef; border-bottom: 1px dashed rgb(187 187 187);">
                        <div>
                            <label for="game" class="juse_label">伺服器：</label>
                            <span style="color: #f58220; font-size: 0.14rem; font-weight: bold;">{{ '【S' + rolerInfo.DSID + '】' + rolerInfo.DSNAME }}</span>
                        </div>
                        <div>
                            <label for="game"  class="juse_label">角色：</label>
                            <span style="color: #f58220; font-size: 0.14rem; font-weight: bold;">{{ '【LV' + rolerInfo.DRLEVEL + '】' + rolerInfo.DRNAME }}</span>
                        </div>
                    </div>
                    <div class="flex-column-jl-c"  style="width: 100%; padding: 8px; background: #efefef;">
                        <span>
                            下單時間：
                            <a-date-picker :value="startTime" @change="onChangeSdate" size="small" placeholder="開始時間" style="width: 36%" />-
                            <a-date-picker :value="endTime" @change="onChangeEdate" size="small" placeholder="結束時間" style="width: 36%" />
                            <!-- <a-range-picker @change="onChange" size="small" style="width: 76%" /> -->
                            <!-- <input class="inputDate" type="date"> -->
                        </span>
                        <span style="display: block; margin: 0.08rem 0; font-weight: bold;">
                            儲值總金額：
                            <span style="color: #f58220;">{{ tableData.SUMMONEY }}</span>
                        </span>
                    </div>
                    <a-spin :spinning="spinning" tip="Loading..." style="height: 100%;">
                        <a-table
                            :columns="columns"
                            :data-source="tableData.data"
                            size="small"
                            :row-key="(record, index) => { return index }" />
                    </a-spin>
                </a-tab-pane>
                <a-tab-pane key="blance" tab="平臺幣餘額">
                    <div style="font-size: 0.14rem;">
                        <span>&nbsp;&nbsp;&nbsp;您的平臺幣餘額为：</span>
                        <span style="font-size: 20px; font-weight: bold; color: #1890ff;">$&nbsp;{{ balance }}</span>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="changePass" tab="更改密碼">
                    <div style="font-size: 0.14rem;">
                        <span style="margin: 0px 12px 8px; display: block;"><MailOutlined style="color: #ff66cc;" />&nbsp;&nbsp;郵箱驗證更改：</span>
                        <div v-if="!isEmail" class="email-boxM">
                            <span v-if="!changeBingding" style="font-size: 12px; color: red;">您好！首次使用郵箱驗證功能需要先綁定您的郵箱</span>
                            <span v-if="changeBingding" style="font-size: 12px; color: #cccccc; margin-bottom: 8px; display: block;">{{ '綁定郵箱：' + communication.email }}</span>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="code"  style="flex: 1;"><i>*</i>&nbsp;旧郵箱驗證：</label>
                                <a-input-group id="code" compact  style="width: 70%;">
                                    <a-input v-model:value="upParams.bdoCode" placeholder="請輸入驗證碼" style="width: 56%" />
                                    <a-button type="primary" @click="getAuthCode(9)" :disabled="timing2">{{ timing2 ? count2 + 'S' : '獲取驗證碼'}}</a-button>
                                </a-input-group>
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="email" style="flex: 1;"><i>*</i>&nbsp;{{ changeBingding ? '新郵箱：' : '郵箱：'}}</label>
                                <a-input id="email" v-if="!changeBingding" @change="(e) => { errorText.email.eNumber = ''; bingdingParams.EMAIL = e.target.value }" placeholder="請輸入您的郵箱" style="width: 76%;" />
                                <a-input id="emailcm" v-if="changeBingding" v-model:value="upParams.email" placeholder="請輸入您的郵箱" style="width: 76%;" />
                                <span class="tips">{{ errorText.email.eNumber }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="code"  style="flex: 1;"><i>*</i>&nbsp;驗證碼：</label>
                                <a-input-group id="code" compact  style="width: 76%;">
                                    <a-input v-model:value="bingdingParams.AuthCode" @change="() => { errorText.email.eCodeTips = '' }" placeholder="請輸入驗證碼" style="width: 60%" />
                                    <a-button v-if="!changeBingding" type="primary" @click="sendAuthCode(0)">{{ errorText.email.eCode ? '重新獲取' : '獲取驗證碼'}}</a-button>
                                    <a-button v-if="changeBingding" type="primary" @click="getAuthCode(4)" :disabled="timing3">{{ timing3 ? count3 + 'S' : '獲取驗證碼'}}</a-button>
                                </a-input-group>
                                <span class="tips">{{ errorText.email.eCodeTips }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jc-c">
                                <a-button v-if="!changeBingding" type="primary" :loading="bindLoading" @click="bindingEmail(0)" style="width: 60%;">確認綁定</a-button>
                                <a-button v-if="changeBingding" @click="() => { changeBingding = false; isEmail = true }" style="width: 40%; margin-right: 8px;">返回</a-button>
                                <a-button v-if="changeBingding" type="primary" :loading="bindLoading" @click="getUpBindCode()" style="width: 40%; margin-left: 8px;">確認綁定</a-button>
                            </div>
                        </div>
                        <div v-else class="email-boxM">
                            <!-- <div class="email-item display-f flex-row-jl-c">
                                <label for="email" style="flex: 1;"><i>*</i>&nbsp;原密碼：</label>
                                <a-input-password id="email" placeholder="請輸入原密碼" style="width: 78%;" />
                            </div> -->
                            
                            <span style="font-size: 12px; color: #cccccc; margin-bottom: 8px; display: block;">{{ '綁定郵箱：' + communication.email }} <a @click="changeEmail">修改...</a></span>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="pwd" style="flex: 1;"><i>*</i>&nbsp;新密碼：</label>
                                <a-input-password id="pwd" v-model:value="newPwd" @change="() => { errorText.accounts.numberTips = '' }" placeholder="請輸入新密碼" style="width: 76%;" />
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="newPwd" style="flex: 1;"><i>*</i>&nbsp;確認密碼：</label>
                                <a-input-password id="newPwd" v-model:value="confirmPwd.UPWD" @change="() => { errorText.accounts.numberTips = '' }" placeholder="請輸入新密碼" style="width: 76%;" />
                                <span class="tips">{{ errorText.accounts.numberTips }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jl-c">
                                <label for="Pcode"  style="flex: 1;"><i>*</i>&nbsp;驗證碼：</label>
                                <a-input-group id="Pcode" compact  style="width: 76%;">
                                    <a-input v-model:value="confirmPwd.AuthCode" @change="() => { errorText.accounts.codeTips = '' }" placeholder="請輸入驗證碼" style="width: 60%" />
                                    <a-button type="primary" @click="sendAuthCode(1)">獲取驗證碼</a-button>
                                </a-input-group>
                                <span class="tips">{{ errorText.accounts.codeTips }}</span>
                            </div>
                            <div class="email-item display-f flex-row-jc-c">
                                <a-button type="primary" :loading="pwdLoading" @click="changePwd" style="width: 60%;">確認更改</a-button>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="聯繫客服">
                    <div style="font-size: 0.14rem;">
                        &nbsp;&nbsp;&nbsp;<a href="https://www.facebook.com/MonsterinPocket/">聯繫VIP客服專員...</a>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import { getReqData, getReqDataNoToken, getBalanceAPI, getOrderStatusAPI, getAllPaymentAPI, sendAuthCodeAPI, bindingEmailAPI, changePwdAPI, loginoutAPI, getCommunicationAPI, saveCommunicationAPI, receiveGiftAPI, getVerifyCodeAPI, getUpBindCodeAPI } from '../api/requests'
import secret from '../api/secret'
import { MailOutlined } from '@ant-design/icons-vue';

export default {
    name: 'member',

    components: {
      MailOutlined,
    },

    data () {
        return {
            mobileDom: false,
            PCDom: false,
            balance: 0,
            orderData: {
                queryed: false,
                uuid: '',
                msg: '',
            },
            spinning: false,
            recordParams: {
                UID: '',
                PGID: '',
                STIME: '',
                ETIME: ''
            },
            startTime: null,
            endTime: null,
            columnsP: [
                {
                    title: '角色',
                    dataIndex: 'DRNAME',
                    key: 'DRNAME'
                },
                {
                    title: '伺服器',
                    dataIndex: 'DSID',
                    key: 'DSID'
                },
                {
                    title: '金額',
                    dataIndex: 'MONEY',
                    key: 'MONEY'
                },
                {
                    title: '儲值方式',
                    dataIndex: 'PWAY',
                    key: 'PWAY'
                },
                {
                    title: '时间',
                    dataIndex: 'ATIME',
                    key: 'ATIME'
                }
            ],
            columns: [
                {
                    title: '金額',
                    dataIndex: 'MONEY',
                    key: 'MONEY'
                },
                {
                    title: '儲值方式',
                    dataIndex: 'PWAY',
                    key: 'PWAY'
                },
                {
                    title: '时间',
                    dataIndex: 'ATIME',
                    key: 'ATIME'
                }
            ],
            tableData: [],
            total: 0,
            rolerInfo: '',
            errorText: {
                email: {
                    eNumber: '',
                    eCode: false,
                    eCodeTips: ''
                },
                accounts: {
                    numberTips: '',
                    codeTips: '',
                }
            },
            isEmail: true,
            bingdingParams: {
                UID: '', // 用户UID，必传
                UNAME: '', // 用户账号，必传
                EMAIL: '', // 邮箱，必传
                AuthCode: '', // 验证码，必传
                TYPE: '', // 类型（0为绑定邮箱，1为修改邮箱）
            },
            bindLoading: false,
            confirmPwd: {
                UID: '',
                UNAME: '',
                UPWD: '',
                AuthCode: '',
            },
            newPwd: '',
            pwdLoading: false,
            changeBingding: false,
            userInfo: '',
            communication: {
                link_id: "",
                link_type: "",
                paymoney: 0,
                email: '',
                giftcode: {
                    email: 0,
                    monthvip: 0
                }
            },
            initTimer: '',
            count: 60,
            frequency: 0,
            timing: false,
            activeKeym: 'personalCenter',
            upParams: {
                bdnCode: '',
                bdoCode: '',
                email: ''
            },
            initTimer2: "",
            count2: 60,
            timing2: false,
            initTimer3: "",
            count3: 60,
            timing3: false,
        }
    },
    created () {
        if (window.isMobile == 1) {
            this.mobileDom = true;
            this.PCDom = false;
        }
        if (window.isPC == 1) {
            this.mobileDom = false;
            this.PCDom = true;
        }
        if (this.$store.state.rolerInfo) {
            this.rolerInfo = this.$store.state.rolerInfo;
        } else {
            this.rolerInfo = JSON.parse(secret.des_decrypt(localStorage.getItem('rolerInfo')));
        }
        this.userInfo = JSON.parse(secret.des_decrypt(localStorage.getItem('info')));
        this.getCommunication();
        this.getAllPayment();
    },
    methods: {
        getBalance () {
            let balancePar = {};
            balancePar.uid = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            balancePar.uname = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            // balancePar.token = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token;
            getBalanceAPI(getReqData(balancePar)).then((res) => {
                if (res.data.code == 0) {
                    this.balance = res.data.data.blance;
                }
            })
        },
        getOrder (e) {
            if (!e) {
                this.$message.warning("請輸入訂單號！");
                return
            }
            this.spinning = true;
            let order = {};
            order.uuid = e;
            this.orderData.uuid = e;
            // B500F0C2204DDCD8A8D784F5A5F639CA
            getOrderStatusAPI(getReqData(order)).then((res) => {
                this.orderData.queryed = true;
                if (res.data == 0) {
                    console.log('【訂單狀態】', res.data);
                } else {
                    this.orderData.msg = res.data.msg;
                }
                this.spinning = false;
            })
        },
        changeTab (e) {
            this.activeKeym = e;
            switch (e) {
                case 'blance':
                    this.getBalance()
                    break;
                case 'order':
                    // this.getOrder()
                    break;
                default:
                    break;
            }
        },
        getAllPayment() {
            this.recordParams.UID = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            this.recordParams.PGID = 10;
            getAllPaymentAPI(this.recordParams).then((res) => {
                this.tableData = res.data.data;
                this.total = this.tableData.length;
            })
        },
        onChange(date, dateString) {
            this.recordParams.STIME = dateString[0];
            this.recordParams.ETIME = dateString[1];
            this.getAllPayment()
        },
        onChangeSdate(date, dateString) {
            if (this.recordParams.ETIME && (new Date(this.recordParams.ETIME) < new Date(dateString))) {
                this.recordParams.STIME = this.recordParams.ETIME;
                this.recordParams.ETIME = dateString;
                this.startTime = this.endTime;
                this.endTime = date;
                this.getAllPayment()
            } else {
                this.recordParams.STIME = dateString;
                this.startTime = date;
                if (this.recordParams.ETIME) {
                    this.getAllPayment()
                }
            }
        },
        onChangeEdate(date, dateString) {
            if (this.recordParams.STIME && (new Date(this.recordParams.STIME) > new Date(dateString))) {
                this.recordParams.ETIME = this.recordParams.STIME;
                this.recordParams.STIME = dateString;
                this.endTime = this.startTime;
                this.startTime = date;
                this.getAllPayment()
            } else {
                this.recordParams.ETIME = dateString;
                this.endTime = date;
                if (this.recordParams.STIME) {
                    this.getAllPayment()
                }
            }
        },
        sendAuthCode(type) {
            if (type == 0) {
                if (!this.bingdingParams.EMAIL) {
                    this.errorText.email.eNumber = '郵箱號不能為空！';
                    return
                }
            }
            if (type == 1 || type == 2) {
                if (!this.bingdingParams.EMAIL) {
                    this.errorText.email.eNumber = '郵箱號不能為空！';
                    return
                }
            }
            let codeParams = {};
            codeParams.UID = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            codeParams.EMAIL = this.bingdingParams.EMAIL;
            codeParams.TYPE = type;
            sendAuthCodeAPI(codeParams).then((res) => {
                if (res.data.code == 1) {
                    this.$message.success('發送成功!請到郵箱查看驗證碼。');
                } else if (res.data.code == -3) {
                    this.$message.error('發送失敗請重新獲取!');
                    this.errorText.email.eCodeTips = '發送失敗請重新獲取!';
                    this.errorText.email.eCode = true;
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        bindingEmail(type) {
            if (!this.bingdingParams.EMAIL) {
                this.errorText.email.eNumber = '郵箱號不能為空！';
                return
            }
            if (!this.bingdingParams.AuthCode) {
                this.errorText.email.eCodeTips = '請輸入驗證碼!';
                return
            }
            this.bindLoading = true;
            this.bingdingParams.UID = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            this.bingdingParams.UNAME = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            this.bingdingParams.TYPE = type;
            bindingEmailAPI(this.bingdingParams).then((res) => {
                if (res.data.code == -5) {
                    this.$message.error('驗證碼錯誤,請重新輸入!');
                }
                if (res.data.code == -6) {
                    this.$message.error('該用戶已綁定郵箱!');
                    this.isEmail = true;
                }
                if (res.data.code == 1) {
                    this.$message.success('綁定成功!');
                    this.isEmail = true;
                }
                if (res.data.code == -7) {
                    this.$message.error('綁定失敗，該郵箱已綁定其他用戶!');
                }
                if (res.data.code == 0 || res.data.code == -2 || res.data.code == -3 || res.data.code == -4) {
                    this.$message.error('綁定失敗!');
                }
                this.bindLoading = false;
            })
        },
        changePwd() {
            var _this = this;
            this.confirmPwd.UID = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            this.confirmPwd.UNAME = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            if (this.confirmPwd.UPWD !== this.newPwd) {
                this.errorText.accounts.numberTips = '兩次輸入密碼不一致，請重新確認！';
                return
            }
            if (!this.confirmPwd.AuthCode) {
                this.errorText.accounts.codeTips = '請輸入驗證碼！';
                return
            }
            this.pwdLoading = true;
            changePwdAPI(this.confirmPwd).then ((res) => {
                if (res.data.code == 1) {
                    this.$message.success('更改密碼成功!');
                    setTimeout(() => {
                        _this.signOut()
                    }, 1000)
                } else {
                    this.$message.error(res.data.msg);
                }
                this.pwdLoading = false;
            })
        },
        changeEmail() {
            this.changeBingding = true;
            this.isEmail = false;
        },
        callback(val) {
            console.log(val);
        },
        signOut () {
            let signOutParams = {
                duid: '',
                time: '',
                sign: ''
            }
            loginoutAPI(getReqData(signOutParams)).then((res) => {
                if (res.data.code == 0) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('info');
                    this.$store.commit('saveUserInfo', '');
                    window.location.reload();
                } else {
                    this.$message.warning(res.data.msg);
                }
            })
        },
        checkType(e) {
            this.communication.link_type = e.target.value;
        },
        getCommunication() {
            let temp = {};
            temp.uid = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            temp.uname = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            temp.token = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token;
            temp.language = 1;
            temp.pgid = 10;
            getCommunicationAPI(getReqData(temp)).then((res) => {
                this.communication = res.data.data;
                if (!this.communication.link_type) {
                    this.communication.link_type = '1';
                }
                if (this.communication.email) {
                    this.isEmail = true;
                    this.bingdingParams.EMAIL = res.data.data.email;
                    this.communication.email = this.communication.email.replace(/(\d{3})\d*(\d{2})/, '$1****$2');
                } else {
                    this.isEmail = true;
                }
                switch (this.communication.viplevel) {
                    case 1:
                        this.communication.vipName = '鉑金vip';
                        break;

                    case 2:
                        this.communication.vipName = '鑽石vip';
                        break;

                    case 3:
                        this.communication.vipName = '大師vip';
                        break;

                    case 4:
                        this.communication.vipName = '王者vip';
                        break;
                
                    default:
                        break;
                }
            })
        },
        saveCommunication() {
            let tempStr = {};
            tempStr.uid = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            tempStr.uname = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            tempStr.token = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token;
            tempStr.language = 1;
            tempStr.pgid = 10;
            tempStr.link_id = this.communication.link_id;
            tempStr.link_type = this.communication.link_type;
            if (!tempStr.link_id) {
                this.$message.error('請輸入您的ID!');
                return
            }
            saveCommunicationAPI(getReqData(tempStr)).then((res) => {
                if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                    this.getCommunication();
                }
            })
        },
        lqTips() {
            this.$message.warning('領取失敗，首次領取請聯繫客服！');
        },
        vipGif(type) {
            this.timing = true;
            this.polling();
            this.receiveConfig(type);
        },
        receiveConfig(type) {
            let parStr = {};
            parStr.gifttype = type; // 领取的礼包类型
            parStr.uname = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname;
            parStr.uid = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid;
            parStr.token = JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token;
            parStr.pgid = 10;
            parStr.language = 1;
            receiveGiftAPI(getReqData(parStr)).then((res) => {
                if (res.data.code == 0) {
                    this.$message.success('領取成功！');
                    this.showReceive = false;
                } else {
                    this.$message.error('領取失敗！' + res.data.msg);
                }
            }).catch((data) => {
                console.log('[error]', data);
            })
        },
        // 倒计时
        polling() {
            let _this = this;
            if (_this.count == 0) {
                clearTimeout(_this.initTimer);
                this.timing = false;
                _this.count = 60;
            } else {
                _this.initTimer = setTimeout(function () {
                    _this.count--;
                    _this.polling ();
                }, 1000);
            }
        },
        goChangeEmail() {
            this.activeKeym = 'changePass';
        },

        // 获取验证码
        getAuthCode(type) {
            let codeParams = {
                uname: JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname,
                uid: JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid,
                token: JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token,
                pgid: 10,
                language: 1,
                rid: type,
                email: this.upParams.email,
            }
            if (type === 9) {
                codeParams.email = this.bingdingParams.EMAIL
            } else {
                codeParams.email = this.upParams.email
            }
            if (!codeParams.email) {
                this.$message.error("郵箱號不能為空！")
                return;
            }
            getVerifyCodeAPI(getReqDataNoToken(codeParams)).then(res => {
                if (res.data.code == 0) {
                    if (type === 9) {
                        this.timing2 = true
                        this.pollingo();
                    } else {
                        this.timing3 = true;
                        this.pollingN();
                    }
                    this.$message.success('發送成功!請到郵箱查看驗證碼。');
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        getUpBindCode() {
            const lsStr = {
                uname: JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uname,
                uid: JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).uid,
                token: JSON.parse(secret.des_decrypt(localStorage.getItem('info'))).token,
                pgid: 10,
                language: 1,
                scode: this.bingdingParams.AuthCode,
                email: this.upParams.email,
                yscode: this.upParams.bdoCode
            }
            if (!this.upParams.email) {
                this.$message.error('請輸入新郵箱!')
                return;
            }
            if (this.bingdingParams.EMAIL == this.upParams.email) {
                this.$message.error('新郵箱和原郵箱不能相同!')
                return
            }
            if (!lsStr.yscode) {
                this.$message.error('請輸入旧郵箱驗證碼!')
                return;
            }
            if (!lsStr.scode) {
                this.$message.error('請輸入新郵箱驗證碼!')
                return;
            }
            getUpBindCodeAPI(getReqDataNoToken(lsStr)).then(res => {
                if (res.data.code == 0) {
                    this.getCommunication();
                    this.$message.success(res.data.msg)
                    this.isEmail = true;
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        pollingo() {
            let _this = this;
            if (_this.count2 == 0) {
                clearTimeout(_this.initTimer2);
                this.timing2 = false;
                _this.count2 = 60;
            } else {
                _this.initTimer2 = setTimeout(function () {
                _this.count2--;
                _this.pollingo();
                }, 1000);
            }
        },
        pollingN() {
            let _this = this;
            if (_this.count3 == 0) {
                clearTimeout(_this.initTimer3);
                this.timing3 = false;
                _this.count3 = 60;
            } else {
                _this.initTimer3 = setTimeout(function () {
                _this.count3--;
                _this.pollingN();
                }, 1000);
            }
        }
    }
}
</script>

<style  scoped>
    .fontStyle {
        font-size: 14px;
        color: #666666;
        font-weight: normal;
    }
    .order-box {
        padding: 12px;
        background: #e5e5e5;
        margin-top: 16px;
    }
    .inputDate {
        width: 36%;
        border: 1px solid #dddddd;
    }
    .email-box {
        width: 420px;
    }
    .email-box .email-item {
        padding-bottom: 14px;
        margin-bottom: 8px;
        position: relative;
    }
    .email-box .email-item .tips{
        position: absolute;
        left: 78px;
        bottom: -4px;
        font-size: 12px;
        color: red;
    }
    .email-box .email-item i{
        color: red;
    }
    .email-boxM{
        margin: 0px 11px;
    }
    .email-boxM .email-item {
        padding-bottom: 14px;
        margin-bottom: 8px;
        position: relative;
    }
    .email-boxM .email-item .tips{
        position: absolute;
        left: 78px;
        bottom: -4px;
        font-size: 12px;
        color: red;
    }
    .email-boxM .email-item i{
        color: red;
    }

    .vip-PC {
        height: calc(100vh - 340px);
        min-height: 600px;
        overflow: hidden;
        overflow-y: scroll;
    }

    .vip-box {
        font-size: 14px;
        margin: 12px 12px 12px 0px;
        padding: 12px;
        background: rgba(239, 239, 239, 0.6);
        border-radius: 6px;
    }

    .vip-box2 {
        background: #ffffff;
        margin-top: 12px;
        box-shadow: 0px 0px 4px 4px #efefef;
        border-radius: 6px;
        padding: 12px;
        display: flex;
        flex-direction: column;
    }
    .vip-text {
        display: flex;
        flex-direction: column;
    }
    .line {
        margin-bottom: 6px;
    }
    .vip-contact {
        margin-top: 24px;
        background: #efefef;
        padding: 12px;
        width: 460px;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 2px #efefef;
    }
    .vip-contact_m {
        margin-top: 24px;
        background: #efefef;
        padding: 12px;
        border-radius: 4px;
        box-shadow: 0px 0px 2px 2px #efefef;
    }

    .juse_label {
        width: 56px;
        display: inline-block;
        text-align: right;
    }

</style>
