<template>
    <div class="region public-width">
        <h2 style="    text-align: center; font-weight: bold;">【【神寶覺醒：Smash】】許可及服務協議</h2>
        <p>
            【神寶覺醒：Smash】遊戲許可及服務協議（以下簡稱“本協議”）由您與【神寶覺醒：Smash】遊戲服務提供方共同締結，本協議具有合同效力。請您務必審慎閱讀、充分理解各條款內容，特別是免除或者限制【神寶覺醒：Smash】遊戲責任的條款（以下稱“免責條款”）、對用戶權利進行限制的條款（以下稱“限制條款”）、約定爭議解決方式和司法管轄的條款，以及開通或使用某項服務的單獨協議。前述免責、限制及爭議解決方式和管轄條款可能以黑體加粗、顏色標記或其他合理方式提示您注意，包括但不限於本協議第二條、第三條、第四條、第六條、第九條等相關條款，您對該等條款的確認將可能導致您在特定情況下的被動、不便、損失，請您在確認同意本協議之前或在使用【神寶覺醒：Smash】遊戲服務之前再次閱讀前述條款。
            除非您已閱讀並接受本協議所有條款，否則您無權使用【神寶覺醒：Smash】遊戲服務。如果您對本協議或【神寶覺醒：Smash】遊戲服務有意見或建議，可與【神寶覺醒：Smash】遊戲客戶服務部門聯系，我們會給予您必要的幫助。您點擊同意、接受或下一步，或您註冊、使用【神寶覺醒：Smash】遊戲服務均視為您已閱讀並同意簽署本協議。
            如果您未滿18周歲，請在法定監護人的陪同下閱讀本協議，並特別注意未成年人使用條款。如您為未成年人法定監護人，希望合理設定孩子娛樂時間，培養孩子健康遊戲習慣的，可依據相關規則登錄【神寶覺醒：Smash】遊戲成長守護平臺瞭解相關資訊，您使用【神寶覺醒：Smash】遊戲成長守護平臺時，應遵守該平臺上的《成長守護平臺許可及服務協議》。
        </p>
        <h3>一、【定義】</h3>
        <p>
            1.1 本協議：指本協議正文、《【神寶覺醒：Smash】遊戲服務協議》《【神寶覺醒：Smash】遊戲軟體許可及服務協議》《【神寶覺醒：Smash】遊戲隱私政策》《【神寶覺醒：Smash】遊戲隱私保護指引》、遊戲規則及其修訂版本。上述內容一經正式發佈，即為本協議不可分割的組成部分。本協議同時還包括《網路遊戲服務格式化協議必備條款》。<br>
            1.2 遊戲規則：指【神寶覺醒：Smash】遊戲服務提供方不時發佈並修訂的關於【神寶覺醒：Smash】遊戲的用戶守則、玩家條例、遊戲公告、提示及通知等內容。<br>
            1.3 【神寶覺醒：Smash】遊戲服務提供方：指向您提供【神寶覺醒：Smash】遊戲及其服務的【神寶覺醒：Smash】，在本協議中簡稱為“【神寶覺醒：Smash】遊戲”。<br>
            1.4 【神寶覺醒：Smash】遊戲：指由【神寶覺醒：Smash】遊戲負責運營的遊戲的統稱，包括電腦客戶端遊戲、網頁遊戲、HTML5遊戲（H5遊戲）、移動終端遊戲、電視端遊戲以及其他形式的遊戲；【神寶覺醒：Smash】遊戲可能以軟體形式提供，這種情況下，【神寶覺醒：Smash】遊戲還包括該相關軟體及相關文檔。<br>
            1.5 【神寶覺醒：Smash】遊戲服務：指【神寶覺醒：Smash】遊戲向您提供的與遊戲相關的各項線上運營服務。<br>
            1.6 您：又稱“玩家”或“用戶”，指被授權使用【神寶覺醒：Smash】遊戲及其服務的自然人。<br>
            1.7 遊戲數據：指您在使用【神寶覺醒：Smash】遊戲過程中產生的被服務器記錄的各種數據，包括但不限於角色數據、虛擬物品數據、行為日誌、購買日誌等等數據。<br>
        </p>
        <h3>二 【遊戲帳號】</h3>
        <p>
            2.1 您如果需要使用和享受【神寶覺醒：Smash】遊戲，則您需要將您享有使用帳號或【神寶覺醒：Smash】遊戲認可的其他帳號作為遊戲帳號，並按照國家法律法規的相關要求，登錄實名註冊系統並進行實名註冊。您對該【神寶覺醒：Smash】遊戲認可的其他帳號的申請、使用等行為應相應符合【神寶覺醒：Smash】遊戲不時修訂並公佈的《【神寶覺醒：Smash】遊戲服務協議》《【神寶覺醒：Smash】遊戲軟體許可及服務協議》和其他有關前述帳號註冊、使用的規範。
            您進行實名註冊時，應提供有關您本人真實、合法、準確、有效的身份資訊及其他相關資訊，且不得以他人身份資料進行實名註冊。否則，【神寶覺醒：Smash】遊戲有權採取本協議第6.5條第二款規定的一種或多種處理措施，並可公佈處理結果，因此造成的一切後果由您自行承擔，且【神寶覺醒：Smash】遊戲有權要求您賠償因此給【神寶覺醒：Smash】遊戲造成的所有損失。<br>
            2.2 您進一步知悉並同意，您在遊客模式下可能無法進行遊戲儲值或消費。且一旦您卸載或重裝【神寶覺醒：Smash】遊戲，或您更換手機、電腦等終端設備或該等終端設備損壞的，您在該遊客模式下所有遊戲相關數據可能都將會被清空，且無法查詢和恢復。如因此造成您任何損失的，均由您自行承擔。
            如您使用【神寶覺醒：Smash】遊戲認可的第三方帳號作為遊戲帳號使用和享受【神寶覺醒：Smash】遊戲的，您還應遵守有關該第三方帳號的協議、規則，且因該第三方帳號產生的相關問題包括但不限於被盜等，您應自行聯繫該第三方進行解決，【神寶覺醒：Smash】遊戲可視情況提供相應的協助。<br>
            若您發現有他人冒用或盜用您的遊戲帳號及密碼、或任何其他未經您合法授權使用的情形時，應立即以【神寶覺醒：Smash】遊戲要求的有效方式通知【神寶覺醒：Smash】遊戲並告知【神寶覺醒：Smash】遊戲需採取的措施。您通知【神寶覺醒：Smash】遊戲時，應提供與您註冊身份資訊相一致的個人有效身份資訊，【神寶覺醒：Smash】遊戲收到您的有效請求並核實身份後，會根據您的要求或結合具體情況採取相應措施（包括但不限於暫停該帳號的登錄和使用等），【神寶覺醒：Smash】遊戲因根據您的請求採取相應措施而造成您及其他用戶損失的，由您自行承擔。若您沒有提供有效身份資訊或您提供的個人有效身份資訊與所註冊的身份資訊不一致的，【神寶覺醒：Smash】遊戲有權拒絕您的請求，因此造成您損失的，由您自行承擔。<br>
            2.3 您充分理解並同意，為高效利用伺服器資源，如果您3年內未使用遊戲帳號登錄【神寶覺醒：Smash】遊戲，【神寶覺醒：Smash】遊戲有權在提前通知的情況下，對該帳號及其帳號下的遊戲數據及相關資訊採取刪除等處置措施。<br>
            2.4 遊戲帳號是【神寶覺醒：Smash】遊戲按照本協議授權您用於登錄、使用【神寶覺醒：Smash】遊戲及相關服務的標識，其所有權屬於【神寶覺醒：Smash】遊戲。您僅根據本協議及 《【神寶覺醒：Smash】遊戲軟體許可及服務協議》、相關帳號使用協議以及【神寶覺醒：Smash】遊戲為此發佈的專項規則享有遊戲帳號的使用權。您不得將遊戲帳號以任何方式提供給他人使用，包括但不限於不得以轉讓、出租、借用等方式提供給他人作包括但不限於直播、錄製、代打代練等商業性使用。否則，因此產生任何法律後果及責任均由您自行承擔，且【神寶覺醒：Smash】遊戲有權對您的遊戲帳號採取包括但不限於警告、限制或禁止使用遊戲帳號全部或部分功能、刪除遊戲帳號及遊戲數據及其他相關資訊、封號直至註銷的處理措施，因此造成的一切後果由您自行承擔。<br>
            2.5 在【神寶覺醒：Smash】遊戲提供遊戲帳號註銷功能情形下，如您需要註銷遊戲帳號的，可按照該遊戲官方提供的帳號註銷指引進行操作，並應保證滿足遊戲官方公佈的有關遊戲帳號註銷的相關條件，同時同意遊戲官方公佈的遊戲帳號註銷協議和其他有關規則。
            同時也特別提示您， 如您註銷遊戲帳號，該遊戲帳號下的相關遊戲收益也將會被清除。據此，在您註銷本遊戲帳號前請您確保已妥善處理該遊戲帳號下的遊戲收益或相關的遊戲收益已結清。一旦您註銷本遊戲帳號，如屆時該遊戲帳號下還存在遊戲收益的（包括在本遊戲使用期間已產生的及未來可能產生的遊戲收益），【神寶覺醒：Smash】遊戲有權對該遊戲帳號下的全部遊戲收益做清除處理，因此產生的後果由您自行承擔。前述遊戲收益包括但不限於：遊戲會員權益、等級；與遊戲角色成長升級相關的所有數據（包括但不限於經驗值、榮譽值、聲望值、稱號等）；尚未使用的網路遊戲虛擬貨幣、虛擬道具及其他虛擬物品（如卡券、金幣、鑽石、道具及其他等）；已經購買但未到期或未使用完的增值服務；已產生但未消耗完畢的其他遊戲收益或未來預期的遊戲收益等。
        </p>
        <h3>三、【用戶資訊收集、使用及保護】</h3>
        <p>
            3.1 您同意並授權【神寶覺醒：Smash】遊戲為履行本協議之目的收集您的用戶資訊，這些資訊包括您註冊資訊、您遊戲帳號下的遊戲數據以及其他您在使用【神寶覺醒：Smash】遊戲服務的過程中向【神寶覺醒：Smash】遊戲提供或【神寶覺醒：Smash】遊戲基於安全、用戶體驗優化等考慮而需收集的資訊，【神寶覺醒：Smash】遊戲對您的用戶資訊的收集將遵循本協議及相關法律的規定。<br>
            3.2 您充分理解並同意：【神寶覺醒：Smash】遊戲或其合作的第三方可以根據您的用戶資訊，通過短信、電話、郵件等各種方式向您提供關於【神寶覺醒：Smash】遊戲的活動資訊、推廣資訊等各類資訊。<br>
            3.3 您理解並同意：為了更好地向您提供遊戲服務，改善遊戲體驗，【神寶覺醒：Smash】遊戲可收集您作為遊戲帳號的【神寶覺醒：Smash】遊戲認可的其他帳號（第三方帳號）涉及的唯一標識、昵稱、頭像、好友關係以及您授權的其他資訊，以及您在【神寶覺醒：Smash】遊戲中的相關操作資訊、遊戲資訊等資訊（具體包括但不限於您的登錄狀態、對戰資訊/狀態、成就資訊等）並進行使用,並可向您本人或其他用戶或好友展示該等資訊。
            您知悉並同意，如國家有權機關依法要求【神寶覺醒：Smash】遊戲協助調查您遊戲帳號（包括實名身份資訊）及遊戲相關數據（如儲值、消費、交易等數據）等資訊的，【神寶覺醒：Smash】遊戲可將前述相關資訊提供給國家有權機關。<br>
            3.4 您應對通過【神寶覺醒：Smash】遊戲及相關服務瞭解、接收或可接觸到的包括但不限於其他用戶在內的任何人的個人資訊予以充分尊重，您不應以搜集、複製、存儲、傳播或以其他任何方式使用其他用戶的個人資訊，否則，由此產生的後果由您自行承擔。<br>
            3.5 保護用戶資訊及隱私是【神寶覺醒：Smash】遊戲的一項基本原則。除本協議另有規定外，【神寶覺醒：Smash】遊戲服務對用戶資訊收集、使用及保護等將遵循《【神寶覺醒：Smash】遊戲隱私保護指引》等協議規定。除法律另有規定外，如您已同意或授權【神寶覺醒：Smash】遊戲依據前述協議或規定收集、使用或處理涉及您的個人資訊的，在您撤回該同意或授權後，不影響【神寶覺醒：Smash】遊戲在此之前已基於您的同意或授權所進行的個人資訊的收集、使用或處理，【神寶覺醒：Smash】遊戲對此前相關個人資訊收集、使用或處理繼續有效。<br>
        </p>
        <h3>四、【【神寶覺醒：Smash】遊戲服務】</h3>
        <p>
            4.1 在您遵守本協議及相關法律法規的前提下，【神寶覺醒：Smash】遊戲給予您一項個人的、不可轉讓及非排他性的許可，以使用【神寶覺醒：Smash】遊戲服務。您僅可為非商業目的使用【神寶覺醒：Smash】遊戲服務，包括：<br>
            （1）接收、下載、安裝、啟動、升級、登錄、顯示、運行和/或截屏【神寶覺醒：Smash】遊戲；<br>
            （2）創建遊戲角色，設置網名，查閱遊戲規則、用戶個人資料、遊戲對局結果，開設遊戲房間、設置遊戲參數，在遊戲中購買、使用遊戲道具、遊戲裝備、遊戲幣等，使用聊天功能、社交分享功能；<br>
            （3）使用【神寶覺醒：Smash】遊戲支持並允許的其他某一項或幾項功能。<br>
            4.2 您在使用【神寶覺醒：Smash】遊戲服務過程中不得未經【神寶覺醒：Smash】遊戲許可以任何方式錄製、直播或向他人傳播【神寶覺醒：Smash】遊戲內容，包括但不限於不得利用任何第三方軟體進行網路直播、傳播等。<br>
            4.3 在【神寶覺醒：Smash】遊戲以軟體形式提供的情況下，您在使用【神寶覺醒：Smash】遊戲及【神寶覺醒：Smash】遊戲服務時還應符合本協議第五條關於軟體許可的規定。<br>
            4.4 本條及本協議其他條款未明示授權的其他一切權利仍由【神寶覺醒：Smash】遊戲保留，您在行使這些權利時須另外取得【神寶覺醒：Smash】遊戲的書面許可。<br>
            4.5 如果您違反本協議任何約定的，【神寶覺醒：Smash】遊戲有權採取本協議第6.5條第二款規定的一種或多種處理措施，並可公佈處理結果，且（/或）有權要求您賠償因您違約行為而給【神寶覺醒：Smash】遊戲造成的所有損失。<br>
            4.6 您充分理解並同意，【神寶覺醒：Smash】遊戲有權依合理判斷對違反有關法律法規或本協議規定的行為進行處理，對違法違規的任何用戶採取適當的法律行動，並依據法律法規保存有關資訊向有關部門報告等，用戶應獨自承擔由此而產生的一切法律責任。<br>
            4.7 您充分理解並同意，因您違反本協議或相關服務條款的規定，導致或產生第三方主張的任何索賠、要求或損失，您應當獨立承擔責任；【神寶覺醒：Smash】遊戲因此遭受損失的，您也應當一併賠償。<br>
            4.8 您充分理解並同意：遊戲裝備、遊戲幣及其他遊戲道具等是【神寶覺醒：Smash】遊戲服務的一部分，【神寶覺醒：Smash】遊戲在此許可您依本協議而獲得其使用權。您購買、使用遊戲裝備、遊戲幣及其他遊戲道具等應遵循本協議、遊戲具體規則的要求。同時，遊戲裝備、遊戲幣及其他遊戲道具等可能受到一定有效期限的限制，即使您在規定的有效期內未使用，除不可抗力或可歸責於【神寶覺醒：Smash】遊戲的原因外，一旦有效期屆滿，將會自動失效。如相關遊戲幣、遊戲裝備及其他遊戲道具等遊戲物品沒有標明使用期限，或者標明的使用期限為“永久”或其他具有與“永久”相同或類似意思的內容的（如“無限期”、“無限制”等），則其使用期限為自您獲得該遊戲物品之日起至該遊戲終止運營之日止。
            您充分理解並同意：為更好地向用戶提供【神寶覺醒：Smash】遊戲服務，【神寶覺醒：Smash】遊戲有權對遊戲相關內容（包括但不限於遊戲角色、遊戲裝備及其他遊戲道具的設計、性能及相關數值設置等）作出調整、更新或優化。<br>
            4.9 您充分理解並同意：為保障您遊戲帳號安全，為營造公平、健康及安全的遊戲環境，在您使用【神寶覺醒：Smash】遊戲服務的過程中，在不違反相關法律規定情況下，【神寶覺醒：Smash】遊戲可以通過技術手段瞭解您終端設備的隨機存儲記憶體以及與【神寶覺醒：Smash】遊戲同時運行的相關程式。一經發現有任何未經授權的、危害【神寶覺醒：Smash】遊戲服務正常運營的相關程式，【神寶覺醒：Smash】遊戲可以收集所有與此有關的資訊並採取合理措施予以打擊。<br>
            4.10 您充分理解並同意：為了保證您及其他用戶的遊戲體驗，【神寶覺醒：Smash】遊戲有權轉移或者清除【神寶覺醒：Smash】遊戲伺服器上存儲的一些過往的遊戲數據。
            您充分理解並同意：為了營造公平、健康及安全的遊戲環境，鼓勵用戶公平競技，提升您及其他用戶的遊戲體驗，【神寶覺醒：Smash】遊戲建立並實施【神寶覺醒：Smash】遊戲信用評分制度。您同意遵守《【神寶覺醒：Smash】遊戲信用平臺服務協議》及【神寶覺醒：Smash】遊戲發佈的有關【神寶覺醒：Smash】遊戲信用評分的其他相關規則。
            您充分理解並同意：【神寶覺醒：Smash】遊戲可將您在使用【神寶覺醒：Smash】遊戲服務過程中產生的個人資訊（包括您遊戲帳號下的遊戲數據以及您在使用【神寶覺醒：Smash】遊戲服務的過程中向【神寶覺醒：Smash】遊戲提供或【神寶覺醒：Smash】遊戲基於安全、用戶體驗優化等考慮而需收集的其他個人資訊等)，用於計算您的遊戲信用評分。
            您充分知悉並同意：【神寶覺醒：Smash】遊戲可在您使用的部分或所有【神寶覺醒：Smash】遊戲中以及【神寶覺醒：Smash】遊戲運營的其他產品及服務中，根據您的遊戲信用評分實施相應的激勵機制及限制措施。前述激勵機制，包括向您提供增益服務，具體包括但不限於獎勵遊戲福利、遊戲裝備的試用等；前述限制措施，包括但不限於對您的遊戲收益進行限制、禁止發言及排行榜上榜等。
            有關【神寶覺醒：Smash】遊戲信用評分制度的其他具體規則，詳見《【神寶覺醒：Smash】遊戲信用平臺服務協議》及【神寶覺醒：Smash】遊戲發佈的有關【神寶覺醒：Smash】遊戲信用評分的其他相關規則。
            4.11 【神寶覺醒：Smash】遊戲將按照相關法律法規和本協議的規定，採取切實有效的措施保護未成年人在使用【神寶覺醒：Smash】遊戲服務過程中的合法權益，包括可能採取技術措施、禁止未成年人接觸不適宜的遊戲或者遊戲功能、限制未成年人的遊戲時間、預防未成年人沉迷網路。作為遊戲規則的一部分，【神寶覺醒：Smash】遊戲還將在適當位置發佈【神寶覺醒：Smash】遊戲用戶指引和警示說明，包括遊戲內容介紹、正確使用遊戲的方法以及防止危害發生的方法。所有未成年人用戶都應在法定監護人的指導下仔細閱讀並遵照執行這些指引和說明；其他玩家在使用【神寶覺醒：Smash】遊戲服務的過程中應避免發佈、產生任何有損未成年人身心健康的內容，共同營造健康遊戲環境。<br>
            4.12 您知悉並同意，出現以下情形之一的，【神寶覺醒：Smash】遊戲有權將您的遊戲帳號納入相應的防沉迷系統，採取相應的防沉迷措施：<br>
            （1）系統判斷您未滿18周歲的；或<br>
            （2）您提交的實名身份資訊不規範的；或<br>
            （3）您實名驗證未通過的；或<br>
            （4）遊客模式登錄的；或<br>
            （5）其他國家法律法規政策要求或【神寶覺醒：Smash】遊戲有合理理由認為需要納入防沉迷系統的情形的。<br>
            另外，為了進一步提高實名認證的精准度，最大限度防止未成年人冒用他人身份資訊，【神寶覺醒：Smash】遊戲可能在部分遊戲或針對部分用戶啟用人臉識別驗證，或者從第三方平臺獲取您的遊戲帳號資訊，並基於未成年人保護策略識別記錄您的遊戲行為，並判斷遊戲行為是否符合未成年人遊戲行為特徵。如【神寶覺醒：Smash】遊戲要求您進行人臉識別驗證，而您未通過或拒絕的，【神寶覺醒：Smash】遊戲也會將您的遊戲帳號納入相應的防沉迷系統，採取相應的防沉迷措施。
            對納入相應防沉迷系統的遊戲帳號，【神寶覺醒：Smash】遊戲有權依據國家有關法律法規及政策規定、本協議其他條款規定、【神寶覺醒：Smash】遊戲運營策略或根據您法定監護人的合理要求採取以下一種或多種措施：<br>
            （1）將與您遊戲相關的資訊（包括但不限於您遊戲帳號的登錄資訊、儲值流水資訊等）提供給您的法定監護人，使得您法定監護人可及時或同步瞭解您遊戲情況；<br>
            （2）限制您遊戲帳號的消費額度；<br>
            （3）採取技術措施遮罩某些遊戲或遊戲的某些功能，或限定您遊戲時間或遊戲時長；<br>
            （4）註銷或刪除您遊戲帳號及遊戲數據等相關資訊；<br>
            （5）您法定監護人要求採取的，或【神寶覺醒：Smash】遊戲認為可採取的其他合理措施，以限制或禁止您使用【神寶覺醒：Smash】遊戲；<br>
            （6）國家法律法規或政策要求的相關措施。<br>
            4.13 【神寶覺醒：Smash】遊戲向用戶提供遊戲服務本身屬於商業行為，用戶有權自主決定是否根據【神寶覺醒：Smash】遊戲自行確定的收費專案（包括但不限於購買遊戲內的虛擬道具的使用權以及接受其他增值服務等各類收費專案）及收費標準支付相應的費用，以獲得相應的遊戲服務。如您不按相應標準支付相應費用的，您將無法獲得相應的遊戲服務。
            您知悉並同意：收費專案或收費標準的改變、調整是一種正常的商業行為，您不得因為收費專案或收費標準的改變、調整而要求【神寶覺醒：Smash】遊戲進行賠償或補償。
            您知悉並同意，您使用法定貨幣進行遊戲儲值購買或兌換遊戲虛擬貨幣、虛擬道具及其他虛擬物品或增值服務的，視為您購買了遊戲服務，您所購買或兌換的遊戲虛擬貨幣、虛擬道具及其他虛擬物品或增值服務將不能退還或兌換成法定貨幣，但法律另有強制性規定的除外。
            您知悉並同意，如您進行遊戲儲值購買或兌換遊戲虛擬貨幣的交易因退款等原因被取消的，【神寶覺醒：Smash】遊戲有權就該被取消的交易對應下發到您遊戲帳號中的虛擬貨幣進行扣除。如您遊戲帳號中的虛擬貨幣不足以扣除的，就不足部分，【神寶覺醒：Smash】遊戲可在您遊戲帳號中的虛擬貨幣後續增加時優先扣除。<br>
            4.14 在任何情況下，【神寶覺醒：Smash】遊戲不對因不可抗力導致的您在使用【神寶覺醒：Smash】遊戲服務過程中遭受的損失承擔責任。該等不可抗力事件包括但不限於國家法律、法規、政策及國家機關的命令及其他政府行為或者其他的諸如地震、水災、雪災、火災、海嘯、颱風、罷工、戰爭等不可預測、不可避免且不可克服的事件。<br>
            4.15 【神寶覺醒：Smash】遊戲可能因遊戲軟體BUG、版本更新缺陷、第三方病毒攻擊或其他任何因素導致您的遊戲角色、遊戲道具、遊戲裝備及遊戲幣等帳號數據或遊戲數據發生異常。在數據異常的原因未得到查明前，【神寶覺醒：Smash】遊戲有權暫時凍結該遊戲帳號；若查明數據異常為非正常遊戲行為所致，【神寶覺醒：Smash】遊戲有權恢復遊戲帳號數據至異常發生前的原始狀態（包括向第三方追回被轉移數據），而無須向您承擔任何責任。若數據異常是由於您實施違法違規行為或違反本協議約定的行為所致，【神寶覺醒：Smash】遊戲有權採取本協議第6.5條第二款規定的一種或多種處理措施。
            您理解並同意，如遊戲部署了多個伺服器的，在遊戲運營過程中，為了提高遊戲的可玩性和互動性，創造更好的遊戲環境，【神寶覺醒：Smash】遊戲可結合遊戲伺服器內活躍用戶人數等情況，適時調整伺服器數量，將其中多個伺服器之間的用戶臨時或永久地合併或遷移到同一個伺服器中（即“合服”或“合區”）。
            如遊戲合服或合區的，【神寶覺醒：Smash】遊戲會在合服或合區前通過遊戲內公告或其他方式向您通知，並會公佈有關合服或合區具體規則。合服或合區可能會導致遊戲相關設置、體驗及有關榜單等用戶相關遊戲數據發生變化，但不會對您遊戲裝備、遊戲幣及其他遊戲道具的功能進行調整，具體見遊戲官方公示的有關合服或合區具體規則。如合服或合區導致角色名、戰隊名（如有）及其他相關內容等重名的，遊戲官方可對重名內容隨機設置新名字以作區分。<br>
            4.16 【神寶覺醒：Smash】遊戲未授權您從任何第三方通過購買、接受贈與或者其他的方式獲得遊戲帳號、遊戲道具、遊戲裝備、遊戲幣及其他遊戲服務。【神寶覺醒：Smash】遊戲不對第三方交易行為（第三方交易，是指您從第三方通過購買、接受贈與或者其他的方式獲得遊戲帳號、遊戲道具、遊戲裝備、遊戲幣及其他遊戲服務的行為）負責，並且不受理因任何第三方交易發生糾紛而帶來的申訴。<br>
            4.17 您充分理解到：不同操作系統之間存在不互通的客觀情況，該客觀情況並非【神寶覺醒：Smash】遊戲造成，由此可能導致您在某一操作系統中的儲值和遊戲數據不能順利轉移到另一操作系統中。由於您在不同系統進行切換造成的儲值損失和遊戲數據丟失風險應由您自行承擔，【神寶覺醒：Smash】遊戲對此不承擔任何責任。<br>
            4.18 您充分理解到：【神寶覺醒：Smash】遊戲中可能會設置強制對戰區域或玩法，如果您不同意強制對戰，請您不要進入該遊戲或遊戲區域；您的進入，將被視為同意該玩法並接受相應後果。<br>
            4.19 【神寶覺醒：Smash】遊戲自行決定終止運營【神寶覺醒：Smash】遊戲時或【神寶覺醒：Smash】遊戲因其他任何原因終止運營時，【神寶覺醒：Smash】遊戲會按照國家有關網路遊戲終止運營的相關法律法規規定處理遊戲終止運營相關事宜，以保障用戶合法權益。<br>
        </p>
        <h3>五、【軟體許可】</h3>
        <p>
            5.1 使用【神寶覺醒：Smash】遊戲服務可能需要下載並安裝相關軟體，您可以直接從【神寶覺醒：Smash】遊戲的相關網站上獲取該軟體，也可以從得到【神寶覺醒：Smash】遊戲授權的第三方獲取。如果您從未經【神寶覺醒：Smash】遊戲授權的第三方獲取【神寶覺醒：Smash】遊戲或與【神寶覺醒：Smash】遊戲名稱相同的遊戲，將視為您未獲得【神寶覺醒：Smash】遊戲授權，【神寶覺醒：Smash】遊戲無法保證該遊戲能夠正常使用，並對因此給您造成的損失不予負責。<br>
            5.2 【神寶覺醒：Smash】遊戲可能為不同的終端設備或操作系統開發了不同的軟體版本，包括但不限於windows、ios、android、windows phone、symbian、blackberry、Nintendo Switch等多個應用版本，您應當根據實際情況選擇下載合適的版本進行安裝，下載安裝程式後，您需要按照該程式提示的步驟正確安裝。<br>
            5.3 若【神寶覺醒：Smash】遊戲以軟體形式提供，【神寶覺醒：Smash】遊戲給予您一項個人的、不可轉讓及非排他性的許可。您僅可為非商業目的在單一臺終端設備上下載、安裝、登錄、使用該【神寶覺醒：Smash】遊戲。<br>
            5.4 為提供更加優質、安全的服務，在軟體安裝時【神寶覺醒：Smash】遊戲可能推薦您安裝其他軟體，您可以選擇安裝或不安裝。<br>
            5.5 如果您不再需要使用該軟體或者需要安裝新版，可以自行卸載。如果您願意幫助【神寶覺醒：Smash】遊戲改進產品服務，請告知卸載的原因。<br>
            5.6 為了保證【神寶覺醒：Smash】遊戲服務的安全性和功能的一致性，【神寶覺醒：Smash】遊戲有權對軟體進行更新，或者對軟體的部分功能效果進行改變或限制。<br>
            5.7 軟體新版本發佈後，舊版本的軟體可能無法使用。【神寶覺醒：Smash】遊戲不保證舊版本軟體繼續可用及相應的客戶服務，請您隨時核對並下載最新版本。
        </p>
        <h3>六、【用戶行為規範】</h3>
        <p>
            6.1 您充分瞭解並同意，您必須為自己遊戲帳號下的一切行為負責，包括您所發表的任何內容以及由此產生的任何後果。<br>
            6.2 您除了可以按照本協議的約定使用【神寶覺醒：Smash】遊戲服務之外，不得進行任何侵犯【神寶覺醒：Smash】遊戲的知識產權的行為，或者進行其他的有損於【神寶覺醒：Smash】遊戲或其他第三方合法權益的行為。<br>
            6.3 您在使用【神寶覺醒：Smash】遊戲或【神寶覺醒：Smash】遊戲服務時須遵守法律法規，不得利用【神寶覺醒：Smash】遊戲或【神寶覺醒：Smash】遊戲服務從事違法違規行為，包括但不限於以下行為：<br>
            （一）違反憲法確定的基本原則的；<br>
            （二）危害國家統一、主權和領土完整的；<br>
            （三）洩露國家秘密、危害國家安全或者損害國家榮譽和利益的；<br>
            （四）煽動民族仇恨、民族歧視，破壞民族團結，或者侵害民族風俗、習慣的；<br>
            （五）宣揚邪教、迷信的；<br>
            （六）散佈謠言，擾亂社會秩序，破壞社會穩定的；<br>
            （七）宣揚淫穢、色情、賭博、暴力，或者教唆犯罪的；<br>
            （八）侮辱、誹謗他人，侵害他人合法權益的；<br>
            （九）違背社會公德的；<br>
            （十）有法律、行政法規和國家規定禁止的其他內容的。<br>
            6.4 除非法律允許或【神寶覺醒：Smash】遊戲書面許可，您不得從事下列行為：<br>
            （1）刪除遊戲軟體及其副本上關於著作權的資訊；<br>
            （2）對遊戲軟體進行反向工程、反向彙編、反向編譯或者以其他方式嘗試發現軟體的源代碼或其他保密內容，包括但不限於【神寶覺醒：Smash】遊戲暫未主動公開呈現但已封存在遊戲安裝包中的遊戲元素等；<br>
            （3）對遊戲軟體進行掃描、探查、測試，以檢測、發現、查找其中可能存在的BUG或弱點；<br>
            （4）對遊戲軟體或者軟體運行過程中釋放到任何終端記憶體中的數據、軟體運行過程中客戶端與伺服器端的交互數據，以及軟體運行所必需的系統數據，進行複製、修改、增加、刪除、掛接運行或創作任何衍生作品，形式包括但不限於使用插件、外掛或非經合法授權的第三方工具/服務接入軟體和相關系統；<br>
            （5）修改或偽造軟體運行中的指令、數據，增加、刪減、變動軟體的功能或運行效果，或者將用於上述用途的軟體、方法進行運營或向公眾傳播，無論上述行為是否為商業目的；<br>
            （6）通過非【神寶覺醒：Smash】遊戲開發、授權的第三方軟體、插件、外掛、系統，使用【神寶覺醒：Smash】遊戲及【神寶覺醒：Smash】遊戲服務，或製作、發佈、傳播非【神寶覺醒：Smash】遊戲開發、授權的第三方軟體、插件、外掛、系統；<br>
            （7）對遊戲中【神寶覺醒：Smash】遊戲擁有知識產權的內容進行使用、出租、出借、複製、修改、鏈接、轉載、彙編、發表、出版、建立鏡像站點等；<br>
            （8）建立有關【神寶覺醒：Smash】遊戲的鏡像站點，或者進行網頁（絡）快照，或者利用架設伺服器等方式，為他人提供與【神寶覺醒：Smash】遊戲服務完全相同或者類似的服務；<br>
            （9）將【神寶覺醒：Smash】遊戲的任意部分分離出來單獨使用，或者進行其他的不符合本協議的使用；<br>
            （10）使用、修改或遮蓋【神寶覺醒：Smash】遊戲的名稱、商標或其他知識產權；<br>
            （11）其他未經【神寶覺醒：Smash】遊戲明示授權的行為。<br>
            6.5 您在使用【神寶覺醒：Smash】遊戲服務過程中如有以下任何行為（以下也稱“違法違規行為”）之一的，【神寶覺醒：Smash】遊戲有權視情節嚴重程度，依據本協議及相關遊戲規則的規定，對您採取本條第二款規定的一種或多種處理措施，並可公告處理結果：<br>
            （1）以某種方式暗示或偽稱【神寶覺醒：Smash】遊戲內部員工或某種特殊身份，企圖得到不正當利益或影響其他用戶權益的行為；<br>
            （2）在【神寶覺醒：Smash】遊戲中使用、上傳違法或不當詞語、字元及其他內容的行為，包括用於角色命名；<br>
            （3）以任何方式破壞【神寶覺醒：Smash】遊戲或影響【神寶覺醒：Smash】遊戲服務的正常進行，包括但不限於：<br>
            1）違規及（或）惡意註冊、登錄遊戲的行為，包括但不限於惡意批量註冊遊戲帳號，以及以破壞伺服器鑒權、惡意擠服等方式登錄遊戲行為；<br>
            2）惡意實施可能導致遊戲伺服器宕機、卡頓，或導致其他遊戲玩家掉線、延遲等無法正常遊戲的行為，如利用DDOS對遊戲伺服器或其他玩家進行網路流量攻擊的行為等；<br>
            3)利用遊戲漏洞實施獲益、破壞遊戲環境或給其他玩家造成不良影響的行為；<br>
            4）消極遊戲破壞其他用戶遊戲體驗的行為。包括但不限於惡意傷害或者擊殺本方隊友，惡意組隊，惡意送分、降分，消極掛機、逃跑、故意送人頭，以及其他消極遊戲破壞用戶遊戲體驗的行為；<br>
            5）遊戲數據表現異常，影響【神寶覺醒：Smash】遊戲公平的其他行為。<br>
            （4）使用各種私服、外掛行為；<br>
            （5）使用遊戲同步器（具體包括但不限於使用鍵盤、滑鼠等硬體同步器，以便可以使用一套鍵盤、滑鼠，同時、同步控制多臺電腦進行遊戲的行為），以及使用其他各種可以讓您在遊戲效率或收益數據上表現異常的硬體（包括但不限於“連點器”等，以下統稱“作弊硬體”）；<br>
            （6）傳播非法言論或不當資訊；<br>
            （7）盜取他人遊戲帳號、遊戲物品；<br>
            （8）進行遊戲帳號交易、帳號共用等影響帳號安全的行為；<br>
            （9）未經【神寶覺醒：Smash】遊戲許可，擅自與其他用戶進行遊戲虛擬貨幣、遊戲裝備、遊戲幣及其他遊戲道具等交易，或從第三方通過購買、接受贈與或者其他式獲得遊戲虛擬貨幣、遊戲裝備、遊戲幣及其他遊戲道具或遊戲服務。<br>
            （10）在未經【神寶覺醒：Smash】遊戲授權或認可的折扣管道進行遊戲儲值、進行折扣遊戲道具交易（例如在非【神寶覺醒：Smash】遊戲官方管道低價購買折扣皮膚）或委託未獲【神寶覺醒：Smash】遊戲授權或認可的第三方代為儲值，或者使用未經【神寶覺醒：Smash】遊戲授權或認可的第三方軟體儲值。<br>
            （11）違反本協議任何約定，或違反國家法律法規任何規定的行為；<br>
            （12）其他在行業內被廣泛認可的不當行為。<br>
            前款規定的處理措施包括但不限於：<br>
            （1）警告；及/或<br>
            （2）禁言，包括但不限於遊戲全區禁言、單區禁言以及公共頻道禁言；及/或<br>
            （3）內容重置，包括但不限於重置或強制修改您提供或上傳的非法昵稱、房間名、圖片等非法資訊或內容等；及/或<br>
            （4）減少或限制您的遊戲收益，該遊戲收益包括但不限於遊戲中與遊戲角色成長升級相關的所有數據（如經驗值、榮譽值、聲望值、稱號等）的提升以及遊戲中的道具、裝備、虛擬貨幣等虛擬物品的獲取；及/或<br>
            （5）刪除或註銷遊戲帳號、遊戲數據或其他遊戲相關資訊；及/或<br>
            （6）限制或禁止您進入遊戲中的特定場景或使用遊戲特定功能；及/或<br>
            （7）強制退出遊戲（即“踢出遊戲”），包括但不限於強制退出整個遊戲，或遊戲中的單局對戰，或您正在進行中的其他遊戲行為；及/或<br>
            （8）暫時或永久性地禁止您遊戲帳號登錄【神寶覺醒：Smash】遊戲（以下稱“封號”）；及/或<br>
            （9）暫時或永久性地禁止您違法違規行為涉及的特定終端（包括但不限於PC終端、移動終端等）登錄【神寶覺醒：Smash】遊戲；及/或<br>
            （10）暫停或終止您使用【神寶覺醒：Smash】遊戲及相關服務，或單方通知您終止本協議；及/或<br>
            （11）就您的違法違規行為提起相應民事訴訟，追究您的侵權、違約或其他民事責任，並要求您賠償【神寶覺醒：Smash】遊戲公司因您違法違規行為所受到的損失（包括【神寶覺醒：Smash】遊戲公司所受到的直接經濟損失、名譽或商譽損失以及【神寶覺醒：Smash】遊戲公司對外支付的賠償金、和解費用、律師費用、訴訟費用及其他間接損失），或移交有關行政管理機關給予行政處罰，或者移交司法機關追究您的刑事責任；及/或<br>
            （12）【神寶覺醒：Smash】遊戲通過遊戲規則（包括但不限於用戶守則、玩家條例、遊戲公告、提示及通知）公佈的其他處理措施。<br>
            您知悉並同意，由於外掛及遊戲同步器等作弊硬體具有隱蔽性或用完後即消失等特點，【神寶覺醒：Smash】遊戲有權根據您的遊戲數據和表現異常判斷您有無使用非法外掛或遊戲同步器等作弊硬體行為。<br>
            6.6 您知悉並同意，如【神寶覺醒：Smash】遊戲依據本協議對您的遊戲帳號採取封號處理措施的，具體封號期間由【神寶覺醒：Smash】遊戲根據您違規行為情節而定。<br>
            您知悉並同意：（1）在封號期間，您遊戲帳號中的遊戲虛擬貨幣、遊戲裝備、遊戲幣及其他遊戲道具可能都將無法使用；（2）如前述遊戲虛擬貨幣、遊戲裝備、遊戲幣及其他遊戲道具存在一定有效期，該有效期可能會在封號期間過期，您遊戲帳號解封後，您將無法使用該等已過期的遊戲虛擬貨幣、遊戲裝備、遊戲幣及其他遊戲道具。
        </p>
        <h3>七、【知識產權】</h3>
        <p>
            7.1 【神寶覺醒：Smash】遊戲是【神寶覺醒：Smash】遊戲的知識產權權利人。【神寶覺醒：Smash】遊戲（包括但不限於【神寶覺醒：Smash】遊戲整體、遊戲內所包含的所有美術、音樂、文字作品和其他遊戲構成要素、組成部分，以及【神寶覺醒：Smash】遊戲運行呈現的連續動態畫面）的一切著作權、商標權、專利權、商業秘密等知識產權及其他合法權益，以及與【神寶覺醒：Smash】遊戲相關的所有資訊內容（包括文字、圖片、音頻、視頻、圖表、介面設計、版面框架、有關數據或電子文檔等）均受中華人民共和國法律法規和相應的國際條約保護，【神寶覺醒：Smash】遊戲享有上述知識產權和合法權益，但相關權利人依照法律規定應享有的權利除外。未經【神寶覺醒：Smash】遊戲事先書面同意，您不得以任何方式將【神寶覺醒：Smash】遊戲（包括但不限於【神寶覺醒：Smash】遊戲整體、遊戲內所包含的所有美術、音樂、文字作品和其他遊戲構成要素、組成部分，以及【神寶覺醒：Smash】遊戲運行呈現的連續動態畫面 ）進行商業性使用或通過資訊網絡傳播【神寶覺醒：Smash】遊戲內容。<br>
            7.2 如【神寶覺醒：Smash】遊戲存在可供您自行製作、創作或上傳相關內容的平臺功能或服務的（以下稱”UGC平臺”），針對您使用UGC平臺製作、創作、上傳或發佈的內容（以下稱“UGC內容”），您應確保其合法且不侵犯他人合法權益並應遵守【神寶覺醒：Smash】遊戲在UGC平臺上發佈的相關規則（以下稱“UGC平臺規則”），且您同意：<br>
            （1）如UGC內容含有【神寶覺醒：Smash】遊戲內容（包括但不限於【神寶覺醒：Smash】遊戲整體、遊戲內所包含的所有美術、音樂、文字作品和其他遊戲構成要素、組成部分，以及【神寶覺醒：Smash】遊戲運行呈現的連續動態畫面）及（或）【神寶覺醒：Smash】遊戲公司享有知識產權的其他內容的，則您對UGC內容進行商業目的使用需要經【神寶覺醒：Smash】遊戲公司書面授權同意，並遵守相應的UGC平臺規則。<br>
            （2）針對UGC內容， 您同意不可撤銷地授權【神寶覺醒：Smash】遊戲以任何方式使用該UGC內容（前述“使用”包括但不限於複製、發行、出租、展覽、表演、廣播、修改、改編、資訊網絡傳播及其他方式使用，同時包括商業目的和非商業目的使用，本遊戲內和本遊戲外使用，也包括在UGC平臺內和UGC平臺外使用。為方便描述，以下將前述使用的權利統稱“使用權”），同時授權【神寶覺醒：Smash】遊戲可以將前述使用權轉授權或分許可第三方使用。您承諾【神寶覺醒：Smash】遊戲的該等使用不會侵害任何第三方知識產權及其他合法權益。<br>
            （3） 針對UGC內容，您僅可在【神寶覺醒：Smash】遊戲及【神寶覺醒：Smash】遊戲認可的其他平臺對該UGC內容作出展示或按【神寶覺醒：Smash】遊戲認可方式使用，不得在未經【神寶覺醒：Smash】遊戲許可情況下擅自或授權第三方在其他平臺進行傳播，也不得擅自或授權第三方進行任何出版、商業盈利、二次衍生及其他使用的行為。如違反，【神寶覺醒：Smash】遊戲將保留法律上的追訴權利。<br>
            （4）針對UGC內容，您不可撤銷地授權【神寶覺醒：Smash】遊戲以自己的名義或委託專業第三方對侵犯UGC內容合法權益的行為進行維權，維權形式包括但不限於：監測侵權行為、發送維權函、提起訴訟或仲裁、調解、和解等，【神寶覺醒：Smash】遊戲有權對維權事宜做出決策並獨立實施。<br>
            （5）如根據著作權法等法律規定，您對UGC內容享有發表權、署名權、修改權和保護作品完整權等著作人身權的，您同意【神寶覺醒：Smash】遊戲可自行根據UGC的具體使用情況或使用場景是否為您進行署名以及以什麼方式進行署名，且您不會因【神寶覺醒：Smash】遊戲按本協議約定使用UGC內容而向【神寶覺醒：Smash】遊戲主張發表權、署名權、修改權和保護作品完整權等著作人身權。<br>
            7.3 儘管本協議有其他規定，您在使用【神寶覺醒：Smash】遊戲服務中產生的遊戲數據的所有權和知識產權歸【神寶覺醒：Smash】遊戲所有，【神寶覺醒：Smash】遊戲有權保存、處置該遊戲數據。其中，【神寶覺醒：Smash】遊戲對用戶購買遊戲虛擬貨幣的購買記錄的保存期限將遵守國家法律法規有關規定。對其他遊戲數據的保存期限由【神寶覺醒：Smash】遊戲自行決定，但國家法律法規另有規定的從其規定。<br>
            7.4 【神寶覺醒：Smash】遊戲可能涉及第三方知識產權，而該等第三方對您基於本協議在【神寶覺醒：Smash】遊戲中使用該等知識產權有要求的，【神寶覺醒：Smash】遊戲將以適當方式向您告知該要求，您應當一併遵守。
        </p>
        <h3>八、【遵守當地法律監管】</h3>
        <p>
            8.1 您在使用【神寶覺醒：Smash】遊戲服務過程中應當遵守當地相關的法律法規，並尊重當地的道德和風俗習慣。如果您的行為違反了當地法律法規或道德風俗，您應當為此獨立承擔責任。<br>
            8.2 您應避免因使用【神寶覺醒：Smash】遊戲服務而使【神寶覺醒：Smash】遊戲捲入政治和公共事件，否則【神寶覺醒：Smash】遊戲有權暫停或終止對您的服務。
        </p>
        <h3>九、【管轄與法律適用】</h3>
        <p>
            9.1 本協議簽訂地為中華人民共和國廣東省深圳市南山區。<br>
            9.2 本協議的成立、生效、履行、解釋及糾紛解決，適用中華人民共和國大陸地區法律（不包括衝突法）。<br>
            9.3 若您和【神寶覺醒：Smash】遊戲之間因本協議發生任何糾紛或爭議，首先應友好協商解決；協商不成的，您同意將糾紛或爭議提交至本協議簽訂地有管轄權的人民法院管轄。<br>
            9.4 本協議所有條款的標題僅為閱讀方便，本身並無實際涵義，不能作為本協議涵義解釋的依據。<br>
            9.5 本協議條款無論因何種原因部分無效，其餘條款仍有效，對各方具有約束力。
        </p>
        <h3>十、【其他】</h3>
        <p>
            10.1 【神寶覺醒：Smash】遊戲有權在必要時變更本協議條款，您可以在【神寶覺醒：Smash】遊戲的相關頁面查閱最新版本的協議條款。本協議條款變更後，如果您繼續使用【神寶覺醒：Smash】遊戲服務，即視為您已接受變更後的協議。<br>
            10.2 【神寶覺醒：Smash】遊戲提醒您：抵制不良遊戲，拒絕盜版遊戲；注意自我保護，謹防受騙上當；適度遊戲益腦，沉迷遊戲傷身；合理安排時間，享受健康生活。
        </p>


<!-- 【神寶覺醒：Smash】遊戲公司
【神寶覺醒：Smash】遊戲互動娛樂 | 服務條款 | 隱私保護指引 | 兒童隱私保護指引 | 【神寶覺醒：Smash】遊戲招聘 | 【神寶覺醒：Smash】遊戲客服 | 遊戲列表 | 成長守護平臺 | 廣告服務及商務合作
【神寶覺醒：Smash】遊戲公司 版權所有 -->

    </div>
</template>

<script>
export default {
    name: 'agreement',
    data () {
        return {}
    },
    created () {},
    methods: {}
}
</script>

<style scoped>
    .region {
        height: calc(100vh - 216px);
        margin: 88px 0px 12px;
        box-shadow: 0px 0px 6px 6px #e5e5e5;
        padding: 24px;
        overflow: hidden;
        overflow-y: scroll;
        background: #ffffff;
    }
    h3 {
        font-weight: bold;
    }
</style>
