<template>
    <div
        class="region display-f flex-column-jc public-width"
        :class="mobileRegion ? 'mobileH' : 'pcH'"
    >
        <a-radio-group :default-value="tabValue" button-style="solid" size="large">
            <!-- <a-radio-button value="1" style="width: 120px;text-align: center;" @click="ckeckTab({ checkValue: '1' })">登录</a-radio-button> -->
            <!-- <a-radio-button value="2" style="width: 120px;text-align: center;" @click="ckeckTab({ checkValue: '2' })">注册</a-radio-button> -->
        </a-radio-group>
        <div class="login" v-show="isLogin">
            <div class="loginItem display-f flex-row-jl-c">
                <label for="number"><i>*</i>&nbsp;帳號：</label>
                <input id="number" v-model="params.uname" @blur="monitor({ field: 'number' })" type="text" autoComplete="off" placeholder="請輸入您的帳號">
                <span>{{ errorText1 }}</span>
            </div>
            <div class="loginItem display-f flex-row-jl-c">
                <label for="password"><i>*</i>&nbsp;密碼：</label>
                <input id="password" v-model="params.upwd" @blur="monitor({ field: 'password' })" type="password" autoComplete="off" placeholder="請輸入您的密碼">
                <span>{{ errorText2 }}</span>
            </div>
            <!-- <div class="loginItem display-f flex-row-jc-c">
                <label for="code"><i>*</i>&nbsp;檢測：</label>
                <input id="code" v-model="params.code" type="text" placeholder="請輸入">
                <div class="code">验证码</div>
                <span>验证码错误请重新获取！</span>
            </div> -->
            <div class="loginItem display-f flex-row-jc-c">
                <a-button type="primary" @click="goLogin()">帳號登入</a-button>
                <div style="width: 80px; position: absolute; right: 0; bottom: -32px;">
                    <a href="https://www.facebook.com/MonsterinPocket/" style="font-size: 12px;">忘記密碼？</a>
                </div>
            </div>
        </div>
        <!-- <div class="login" v-show="register">
            <div class="loginItem display-f flex-row-jc-c">
                <label for="number"><i>*</i>&nbsp;邮箱：</label>
                <input id="number" type="text" placeholder="请输入邮箱">
                <span>邮箱不能为空！</span>
            </div>
            <div class="loginItem display-f flex-row-jc-c">
                <label for="password"><i>*</i>&nbsp;密码：</label>
                <input id="password" type="password" placeholder="请输入您的密码">
                <span>！</span>
            </div>
            <div class="loginItem display-f flex-row-jc-c">
                <label for="password"><i>*</i>&nbsp;确认密码：</label>
                <input id="password" type="password" placeholder="请再次输入您的密码">
                <span>密码不一致，请重新输入！</span>
            </div>
            <div class="loginItem display-f flex-row-jc-c">
                <label for="code"><i>*</i>&nbsp;检测：</label>
                <input id="code" type="text">
                <div class="code">验证码</div>
                <span>请输入验证码！</span>
            </div>
            <div>
                <a-radio><a href="">我已閱讀並同意條款和服務</a> &nbsp;<i style="color: red;">*</i></a-radio>
            </div>
            <div class="loginItem display-f flex-row-jc-c">
                <a-button type="primary">注册</a-button>
            </div>
        </div> -->
        <div v-if="mobileRegion" style="margin-top: 1.2rem; color: #fff; font-size: 12px;">
            <div style="padding: 0px 6px;">
                <a href="https://www.facebook.com/MonsterinPocket/">聯繫我們</a>
                <span style="margin: 0px 8px;">|</span>
                <!-- <a href="">隱私政策</a>
                <span>|</span> -->
                <!-- <a href="">服務條例</a> -->
                <router-link  :to="{path:'/agreement'}" >服務條例</router-link>
            </div>
            <div>
                <span>【神寶覺醒：Smash】遊戲公司</span>
            </div>
            <div>
                <span>【神寶覺醒：Smash】遊戲公司 版權所有</span>
            </div>
        </div>
        
        <!-- 区服选择 -->
        <a-modal v-if="mobileRegion" :visible="isModal" title="區服選擇" :closable="false" :centered="true">
            <!-- <div class="sel display-f flex-row-jl-c">
                <label for="game">遊戲名：</label>
                <a-select id="game" style="flex: 1;" size="large" placeholder="請選擇遊戲名"  @change="handleChange">
                    <a-select-option value="觉醒起源">觉醒起源</a-select-option>
                </a-select>
            </div> -->
            <div class="sel display-f flex-row-jl">
                <label for="game">伺服器：</label>
                <div id="game" style="flex: 1;">
                    <a-select size="large" :value="0" style="width: 100%;" placeholder="請選擇伺服器" @change="changeSection">
                        <a-select-option v-for="(item, index) in regSection" :key="index" :value="item.index">{{ item.name }}</a-select-option>
                    </a-select>
                    <a-select class="sel2" size="large" style="width: 100%;" placeholder="請選擇伺服器" @change="changeRegional">
                        <a-select-option
                            v-for="(item, index) in regionalArr"
                            :key="index"
                            :value="item.index"
                        >
                            {{ '【S' + item.DSID + '】' + item.DSNAME }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="sel display-f flex-row-jl-c">
                <label for="game">角色：</label>
                <a-select id="game" :value="rolers.DRID ? rolers.DRID : undefined" style="flex: 1;" size="large" placeholder="請選擇遊戲角色" @change="changeRoler">
                    <a-select-option :value="rolers ? rolers.DRID : null">{{ rolers.DRLEVEL ? ('【LV' + rolers.DRLEVEL + '】' + rolers.DRNAME) : rolers.DRNAME }}</a-select-option>
                </a-select>
            </div>
            <template #footer>
                <!-- <a-button key="back" @click="handleCancel">取消</a-button> -->
                <div style="width: 100%;text-align: center; padding: 8px 0px 12px;">
                    <a-button key="submit" type="primary" @click="handleOk" style="width: 60%;">確定</a-button>
                </div>
            </template>
        </a-modal>
        <a-modal v-else :visible="isModal" title="區服選擇" :closable="false" :centered="true">
            <!-- <div class="sel display-f flex-row-jl-c">
                <label for="game">遊戲名：</label>
                <a-select id="game" style="flex: 1;" size="large" placeholder="請選擇遊戲名"  @change="handleChange">
                    <a-select-option value="觉醒起源">觉醒起源</a-select-option>
                </a-select>
            </div> -->
            <div class="sel sel2 display-f flex-row-jl-c">
                <label for="game">伺服器：</label>
                <div id="game" style="flex: 1;">
                    <a-select size="large" :value="0" style="width: 50%;" placeholder="請選擇伺服器" @change="changeSection">
                        <a-select-option v-for="(item, index) in regSection" :key="index" :value="item.index">{{ item.name }}</a-select-option>
                    </a-select>
                    <a-select size="large" style="width: 50%;" placeholder="請選擇伺服器" @change="changeRegional">
                        <a-select-option
                            v-for="(item, index) in regionalArr"
                            :key="index"
                            :value="item.index"
                        >
                            {{ '【S' + item.DSID + '】' + item.DSNAME }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="sel display-f flex-row-jl-c">
                <label for="game">角色：</label>
                <a-select id="game" :value="rolers.DRID ? rolers.DRID : undefined" style="flex: 1;" size="large" placeholder="請選擇遊戲角色" @change="changeRoler">
                    <a-select-option :value="rolers ? rolers.DRID : null">{{ rolers.DRLEVEL ? ('【LV' + rolers.DRLEVEL + '】' + rolers.DRNAME) : rolers.DRNAME }}</a-select-option>
                </a-select>
            </div>
            <template #footer>
                <!-- <a-button key="back" @click="handleCancel">取消</a-button> -->
                <div style="width: 100%;text-align: center; padding: 8px 0px 12px;">
                    <a-button key="submit" type="primary" @click="handleOk" style="width: 60%;">確定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
import { getSign, getReqData, loginAPI, getAreaAPI, getRoleAPI, tokenLoginAPI } from '../api/requests'
import secret from '../api/secret'

export default {
    name: 'Login',
    data () {
        return {
            mobileRegion: false,
            tabValue: '1',
            isLogin: true,
            register: false,
            params: {
                duid: '',
                time: '',
                sign: '',
                uname: '',
                upwd: ''
            },
            errorText1: '',
            errorText2: '',

            isModal: false,
            areaPramas: {
                DSID: '',
                UID:''
            },
            regionalArr: [],
            regSection: [],
            areaArr: [],
            area: null,
            rolers: {
                DRID: '',
                DRLEVEL: '',
                DRNAME: ''
            },
            roler: null,
            urlParams: this.$route.query
        }
    },
    beforeCreate () {},
    created () {
        if (window.isMobile == 1) {
            this.mobileRegion = true;
        }
        if (window.isPC == 1) {
            this.mobileRegion = false;
        }

        if (this.urlParams.sign) {
            const tempJson = {
                duid: decodeURIComponent(this.$route.query.duid) || '',
                uname: decodeURIComponent(this.$route.query.uname) || '',
                uid: decodeURIComponent(this.$route.query.uid) || '',
                token: this.$route.query.token || '',
                pid: decodeURIComponent(this.$route.query.pid) || '',
                gid: decodeURIComponent(this.$route.query.gid) || '',
                refer: decodeURIComponent(this.$route.query.refer) || '',
            };
            
            let tempStr = getSign(tempJson);
            if (tempStr == decodeURIComponent(this.$route.query.sign)) {
                this.tokenLogin();
            } else {
                this.$message.error('自動登入失敗，請重新登入！');
                // Automatic login failed, please login again!
            }
        }
        // else {
        //     this.transfer();
        // }
    },
    mounted () {},
    methods: {
        // transfer () {
        //     if (this.$route.query.value != 4) {
        //         this.getUserDetail();
        //     } else {
        //         if (localStorage.getItem('info')) {
        //             this.$store.commit('saveUserInfo', JSON.parse(secret.des_decrypt(localStorage.getItem('info'))));
        //         }
        //     } 
        // },
        ckeckTab (data) {
            if (data.checkValue == '1') {
                this.isLogin = true;
                this.register = false;
            } else {
                this.isLogin = false;
                this.register = true;
            }
        },
        monitor (data) {
            switch (data.field) {
                case 'number':
                    if (!this.params.uname) {
                        this.errorText1 = '帳號不能為空！'
                    } else {
                        this.errorText1 = ''
                    }
                    break;
                case 'password':
                    if (!this.params.uname && !this.params.upwd) {
                        this.errorText1 = '帳號不能為空！'
                        this.errorText2 = '密碼不能為空！'
                    } else if (!this.params.upwd) {
                        this.errorText2 = '密碼不能為空！'
                    } else {
                        this.errorText1 = ''
                        this.errorText2 = ''
                    }
                    break;
                default:
                    break;
            }
            
        },
        goLogin () {
            // var _that = this;
            loginAPI(getReqData(this.params)).then((res) => {
                if (res.data.code == 0) {
                    // this.$message.success(res.data.msg);
                    this.$store.commit('saveUserInfo', res.data.data);
                    localStorage.setItem('info', secret.des_encrypt(JSON.stringify(res.data.data)));
                    localStorage.setItem('token', secret.des_encrypt(res.data.data.token));
                    setTimeout(() => {
                        // this.$router.push(this.$route.query.topath);
                        // this.$router.push('/');
                        this.isModal = true;
                        // res.data.data.uid;
                        this.getArea(res.data.data);
                    }, 500)
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        getArea(data) {
            this.areaPramas.UID = data.uid;
            // this.areaPramas.PID = data.pid;
            // this.areaPramas.GID = data.gid;
            getAreaAPI().then((res) => {
                if (res.data.code == 0) {
                    this.areaArr = res.data.data;
                    let num = Math.ceil(this.areaArr.length / 50);
                    for(let i = 0; i < num; i++) {
                        if ((i*50 + 50) > this.areaArr.length) {
                            let nameValue = '【S' + this.areaArr[i*50].DSID + '】' + '~' + '【S' + this.areaArr[this.areaArr.length - 1].DSID + '】';
                            let reqArr = this.areaArr.slice(i*50);
                            this.regSection.push({ id: i, name: nameValue, reqArr: reqArr });
                        } else {
                            let nameValue = '【S' + this.areaArr[i*50].DSID + '】' + '~' + '【S' + this.areaArr[i*50 + 49].DSID + '】';
                            let reqArr = this.areaArr.slice(i*50, i*50 + 50);
                            this.regSection.push({ id: i, name: nameValue, reqArr: reqArr });
                        }
                    }
                    this.regionalArr = this.regSection[0].reqArr;
                } else {
                    this.$message.error(res.data.msg);
                }
                
            })
        },
        getRole() {
            getRoleAPI().then((res) => {
                console.log('【角色】', res.data);
            })
        },
        changeSection(e) {
            this.regionalArr = this.regSection[e].reqArr;
        },
        changeRegional(e) {
            // this.rolers = this.regionalArr[e];
            // this.roler = this.regionalArr[e].DRID;
            this.areaPramas.DSID = this.regionalArr[e].DSID;
            getRoleAPI(this.areaPramas).then((res) => {
                if (res.data.data.length > 0) {
                    this.rolers = res.data.data[0];
                } else {
                    this.rolers = {
                        DRID: '',
                        DRLEVEL: '',
                        DRNAME: '此區服無角色資訊，請重新選擇!'
                    }
                }
            })
        },
        changeRoler(e) {
            console.log('【選擇角色】', e)
        },
        handleOk() {
            if (this.rolers.DRID && this.rolers.DSID) {
                this.$store.commit('saveRolerInfo', this.rolers);
                localStorage.setItem('rolerInfo', secret.des_encrypt(JSON.stringify(this.rolers)));
                this.isModal = false;
                this.$router.push('/')
            } else {
                this.$message.warning('請選擇區服及角色！');
            }
        },
        tokenLogin() {
            const tempStr = {};
            tempStr.uname = decodeURIComponent(this.$route.query.uname);
            tempStr.uid = decodeURIComponent(this.$route.query.uid);
            tempStr.token = decodeURIComponent(this.$route.query.token);
            tempStr.pgid = 10;
            tempStr.language = 1;
            tempStr.duid = decodeURIComponent(this.$route.query.duid);
            tokenLoginAPI(getReqData(tempStr)).then((res) => {
                if (res.data.code == 0) {
                    // this.$message.success(res.data.msg);
                    this.$store.commit('saveUserInfo', res.data.data);
                    localStorage.setItem('info', secret.des_encrypt(JSON.stringify(res.data.data)));
                    localStorage.setItem('token', secret.des_encrypt(res.data.data.token));
                    setTimeout(() => {
                        // this.$router.push(this.$route.query.topath);
                        // this.$router.push('/');
                        this.isModal = true;
                        // res.data.data.uid;
                        this.getArea(res.data.data);
                    }, 500)
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    }
}
</script>

<style scoped>
    .region {
        margin-top: 88px;
        padding-top: 24px;
        margin-bottom: 12px;
        /* box-shadow: 0px 0px 6px 6px #e5e5e5; */
    }
    .mobileH {
        height: calc(100vh - 88px);
    }
    .pcH {
        height: calc(100vh - 216px);
        min-height: 720px;
        /* min-width: 1310px; */
    }
    .login {
        /* width: 38%; */
        /* width: 1.43rem; */
        padding: 24px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 12px;
        margin-top: 12px;
    }
    .loginItem {
        position: relative;
        padding: 4px 0px 20px;
        margin: 12px 0px 0px;
    }
    .loginItem i {
        color: red;
    }
    .loginItem label {
        display: block;
        width: 62px;
        /* width: 0.292rem; */
        /* padding: 0px 2px; */
        font-size: 16px;
        text-align: right;
    }
    .loginItem span {
        color: red;
        font-size: 14px;
        position: absolute;
        left: 62px;
        bottom: 0px;
    }
    .loginItem button {
        /* border: none; */
        /* background: #1890ff; */
        /* color: #fff; */
        height: 42px;
        width: 224px;
        border-radius: 6px;
        margin-left: 62px;
    }
    .loginItem a {
        position: absolute;
        right: 0px;
        bottom: 20px;
    }
    input {
        flex: 1;
        /* width: 1.03rem; */
        height: 42px;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 4px 8px;
    }
    input:focus {
        outline:none;
        box-shadow: 0px 0px 4px 4px #a0c6f7;
        border: 1px solid #ccc;
    }
    .code {
        margin-left: 12px;
        background: #999999;
        height: 42px;
        width: 120px;

        text-align: center;
        line-height: 42px;
        color: #ffffff;
    }

    .sel2 {
        /* width: 30%; */
        margin: 16px 0px;
    }
    .sel label {
        width: 72px;
        font-size: 16px;
        text-align: right;
    }
</style>
