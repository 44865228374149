<template>
  <div>
    <span
      style="
        display: block;
        background: #efefef;
        margin: 12px 12px 0px;
        padding: 8px;
      "
    >
      選擇儲值服務：
      <a-radio-group name="radioGroup" :default-value="2" @change="changeType">
        <a-radio :value="2">儲值到遊戲</a-radio>
        <a-radio :value="1">儲值平臺幣</a-radio>
        <a-radio :value="3">儲值排行榜</a-radio>
      </a-radio-group>
    </span>
    <!-- mobile -->
    <div
      class="mobile display-f flex-row-jl"
      v-show="showTab == 1 && rechargeType == 1"
      style="flex-wrap: wrap; position: relative"
    >
      <div class="display-f flex-row-jl-c mobile_wayList">
        <div
          v-for="item in wayList"
          :key="item.id"
          :class="item.checked ? 'checkWay' : ''"
          @click="checkPayWay(item)"
          style="
            margin-right: 12px;
            border: 1px solid #cccccc;
            border-radius: 8px;
          "
        >
          <img :src="item.icon" alt="" />
        </div>
      </div>

      <div
        @click="isTips = !isTips"
        style="
          width: 16px;
          height: 16px;
          position: absolute;
          right: 12px;
          top: 84px;
          background: #999;
          transform: rotateZ(45deg) skew(0deg, 0deg);
        "
      >
        <!-- <span>?</span> -->
        <div
          style="
            transform: rotateZ(-45deg) skew(0deg, 0deg);
            text-align: center;
            color: #fff;
            line-height: 16px;
            font-size: 14px;
          "
        >
          ?
        </div>
      </div>
      <div class="mask" v-show="isTips" @click="isTips = !isTips">
        <div class="mobileTips">
          <span style="font-weight: bold">備註：</span>
          <p>
            1.該網站為官方指定唯一儲值管道。<br />
            2.儲值成功後，精靈幣會發送至遊戲賬號，請注意查收。<br />
            3.如有問題請聯繫官方臉書-<a
              href="https://www.facebook.com/MonsterinPocket/"
              >【神寶覺醒：Smash】</a
            >，尋找客服幫忙。<br />
            4.官網儲值指南：①登錄帳號→②點擊儲值方式→③點擊檔位→④點擊儲值→⑤跳轉儲值頁面→⑥支付成功→⑦登錄遊戲內點擊心儀的商品→⑧選擇錢包兌換方式→⑨儲值成功。<br />
            5.FB/GOOGLE/APPLE登錄帳號查詢:①登入遊戲介面→②點擊帳號登入，點擊密碼行旁邊的眼睛→③保存帳號和密碼，使用到官網登入<br />
            舉例：如果我是facebook登入，我的登錄帳號是fb開頭的fb******,密碼是6個數字。
          </p>
        </div>
      </div>

      <div style="width: 100%; padding: 0px 6px 6px">
        <span>您的餘額为：</span>
        <span style="font-size: 20px; font-weight: bold; color: #1890ff"
          >$&nbsp;{{ balance }}</span
        >
      </div>
      <div
        class="amount2 display-f flex-column-jc-c"
        v-for="item in payAmount"
        :key="item"
        :class="item.check ? 'checkAmount' : 'noCheck'"
        @click="checkGoods(item)"
      >
        <div class="goods display-f flex-row-jc-c">$&nbsp;{{ item.money }}</div>
        <!-- <div class="price display-f flex-row-jc-c">
                    <span class="currency2">{{ item }}</span>
                    <span class="money">{{ item }}</span>
                </div> -->
      </div>
      <div
        v-if="otherMoney"
        style="
          width: 100%;
          margin: 0px 6px 12px;
          padding: 6px;
          background: #fff;
          border-radius: 8px;
        "
      >
        <span>{{ otherMoney.name + "：1$" }}&nbsp;&nbsp;</span>
        <a-input-number
          size="large"
          :min="otherMoney.min"
          :max="Number(otherMoney.max)"
          @change="changeOther"
          style="border-radius: 8px"
        />
        <span style="color: red">&nbsp;&nbsp;{{ otherMoney.desc }}</span>
      </div>
      <div class="payNow">
        <div class="display-f flex-row-jc-e" style="color: #1890ff">
          <span style="font-size: 18px; font-weight: bold">價格：</span>
          <!-- <span style="margin-right: 6px;">{{ params.currency }}</span> -->
          <span style="font-size: 20px; font-weight: bold"
            >$&nbsp;{{ params.money ? params.money : "0.00" }}</span
          >
        </div>
        <div class="display-f flex-row-jc-c">
          <span>獲得：</span>
          <span>{{ desc ? desc : "無" }}</span>
        </div>
        <div class="display-f flex-row-jc-c">
          <span>兌換渠道：</span>
          <span style="color: #1890ff">{{
            params.pway ? params.pway : "無"
          }}</span>
        </div>
        <a-button
          type="primary"
          @click="buyNow()"
          style="width: 80%; font-size: 16px; height: 48px; margin-top: 24px"
          >儲值</a-button
        >
      </div>
    </div>

    <div
      class="mobile display-f flex-row-jl"
      v-show="showTab == 1 && rechargeType == 2"
      style="flex-wrap: wrap; position: relative"
    >
      <div class="display-f" style="width: 100%; padding: 0px 8px 8px">
        <div style="margin-right: 24px">
          <label for="game">伺服器：</label>
          <span style="color: #f58220; font-size: 0.14rem; font-weight: bold">{{
            "【S" + rolerData.DSID + "】" + rolerData.DSNAME
          }}</span>
        </div>
        <div>
          <label for="game">角色：</label>
          <span style="color: #f58220; font-size: 0.14rem; font-weight: bold">{{
            "【LV" + rolerData.DRLEVEL + "】" + rolerData.DRNAME
          }}</span>
        </div>
      </div>
      <div class="display-f flex-row-jl-c mobile_wayList">
        <div
          v-for="item in wayListNew"
          :key="item.id"
          :class="item.checked ? 'checkWay' : ''"
          @click="checkPayWayRebate(item)"
          style="
            margin-right: 12px;
            border: 1px solid #cccccc;
            border-radius: 8px;
          "
        >
          <img :src="item.icon" alt="" />
        </div>
      </div>

      <div
        @click="isTips = !isTips"
        style="
          width: 16px;
          height: 16px;
          position: absolute;
          right: 6px;
          top: 6px;
          background: #999;
          transform: rotateZ(45deg) skew(0deg, 0deg);
        "
      >
        <!-- <span>?</span> -->
        <div
          style="
            transform: rotateZ(-45deg) skew(0deg, 0deg);
            text-align: center;
            color: #fff;
            line-height: 16px;
            font-size: 14px;
          "
        >
          ?
        </div>
      </div>
      <div class="mask" v-show="isTips" @click="isTips = !isTips">
        <div class="mobileTips">
          <span v-html="tipsData" style="white-space: pre-wrap"></span>
        </div>
      </div>

      <!-- <div style="width: 100%; padding: 0px 6px 6px;">
                <span>您的餘額为：</span>
                <span style="font-size: 20px; font-weight: bold; color: #1890ff;">$&nbsp;{{ balance }}</span>
            </div> -->
      <div
        class="amount2 display-f flex-column-jc-c"
        v-for="(item, index) in rebateGear"
        :key="index"
        :class="item.checked ? 'checkAmount' : 'noCheck'"
        @click="checkRebate(item)"
      >
        <div class="goods display-f flex-row-jc-c">$&nbsp;{{ item.money }}</div>
        <div class="price display-f flex-column-jc-c">
          <span :class="item.checked ? 'currency2' : ''">{{
            item.coin + "鑽石"
          }}</span>
          <span v-if="item.rebate" style="font-size: 0.12rem; color: red">{{
            "贈送" +
            parseInt(item.rebate_value) +
            "鑽石"
          }}</span>
        </div>
        <img
          v-if="item.tag == 1"
          class="angleIcon"
          src="../../assets/img/recommend.png"
          alt=""
        />
        <img
          v-if="item.tag == 2"
          class="angleIcon"
          src="../../assets/img/discount.png"
          alt=""
        />
        <img
          v-if="item.tag == 3"
          class="angleIcon"
          src="../../assets/img/hot.png"
          alt=""
        />
      </div>
      <!-- <div v-if="otherMoney" style="width: 100%; margin: 0px 6px 12px; padding: 6px; background: #fff; border-radius: 8px;">
                <span>{{ otherMoney.name + '：$' }}&nbsp;&nbsp;</span>
                <a-input-number size="large" :min="otherMoney.min" :max="otherMoney.max" @change="changeOther" style="border-radius: 8px;" />
                <span style="color: red;">&nbsp;&nbsp;{{ otherMoney.desc }}</span>
            </div> -->
      <div class="payNow">
        <div class="display-f flex-row-jc-e" style="color: #1890ff">
          <span style="font-size: 18px; font-weight: bold">價格：</span>
          <!-- <span style="margin-right: 6px;">{{ params.currency }}</span> -->
          <span style="font-size: 20px; font-weight: bold"
            >$&nbsp;{{
              diamondsParams.money ? diamondsParams.money : "0.00"
            }}</span
          >
        </div>
        <div class="display-f flex-row-jc-c">
          <span>獲得：</span>
          <span>
            {{ rebateDesc.currency ? rebateDesc.currency : "無" }}
            <span v-if="rebateDesc.add" style="color: red">{{
              "+ " + rebateDesc.add
            }}</span>
            <!-- <b v-show="rebateTipsM" style="color: red;">({{ rebateTipsM }})</b> -->
          </span>
        </div>
        <div class="display-f flex-row-jc-c">
          <span>兌換渠道：</span>
          <span style="color: #1890ff">{{
            payGateParams.pway ? payGateParams.pway : "無"
          }}</span>
        </div>
        <a-button
          type="primary"
          @click="rebateCreateOrder()"
          style="width: 80%; font-size: 16px; height: 48px; margin-top: 24px"
          >儲值</a-button
        >
      </div>
    </div>
    <!-- 排行榜-mobile -->
    <div
      class="mobile rankBox display-f flex-column"
      v-show="showTab == 1 && rechargeType == 3"
    >
      <h3 style="padding: 4px 0px; text-align: center; font-weight: bold">
        {{ payRankData.title }}
      </h3>
      <div
        class="display-f flex-row-js-c rank-item"
        v-for="(eve, id) in payRankData.list"
        :key="id"
      >
        <div v-if="id == 0" class="display-f flex-row-jc-c rankb rankb1">
          {{ id + 1 }}
        </div>
        <div v-else-if="id == 1" class="display-f flex-row-jc-c rankb rankb2">
          {{ id + 1 }}
        </div>
        <div v-else-if="id == 2" class="display-f flex-row-jc-c rankb rankb3">
          {{ id + 1 }}
        </div>
        <div v-else class="display-f flex-row-jc-c rankb">{{ id + 1 }}</div>
        <div>
          {{ eve.drname }}
          <span
            style="
              padding: 4px;
              background: rgb(255 215 0);
              border-radius: 4px;
              color: rgb(255 255 255);
            "
            v-if="rolerData.DRID == eve.drid"
            >本人</span
          >
        </div>
        <div>{{ "$ " + eve.money }}</div>
      </div>
      <div style="padding: 6px">
        <span
          v-html="payRankData.desc"
          style="white-space: pre-wrap; color: rgb(255 0 0)"
        ></span>
      </div>
    </div>
    <!-- PC充值平台币 -->
    <div
      class="pay public-width display-f flex-row-js"
      v-show="showTab == 2 && rechargeType == 1"
    >
      <div class="left">
        <div
          class="left-item display-f"
          style="
            padding-bottom: 12px;
            border-bottom: 1px dashed rgb(170 170 170);
          "
        >
          <div style="margin-right: 24px">
            <label for="game">伺服器：</label>
            <span class="rolerColor">{{
              "[" + rolerData.DSID + "]" + rolerData.DSNAME
            }}</span>
          </div>
          <div>
            <label for="game">角色：</label>
            <span class="rolerColor">{{
              "[" + rolerData.DRLEVEL + "]" + rolerData.DRNAME
            }}</span>
          </div>
        </div>
        <div
          class="left-item display-f flex-row-jl-c"
          style="position: relative"
        >
          <label for="" style="width: 98px; display: block"
            >選擇兌換渠道：</label
          >
          <div class="payWay display-f flex-row-js">
            <div
              class="way"
              v-for="item in wayList"
              :key="item.id"
              :class="item.checked ? 'checkWay' : ''"
              @click="checkPayWay(item)"
            >
              <!-- {{ item.desc }} -->
              <img :src="item.icon" alt="" />
            </div>
          </div>
        </div>
        <div class="left-item display-f flex-row-jl" style="flex-wrap: wrap">
          <div
            class="amount display-f flex-row-js-c"
            v-for="item in payAmount"
            :key="item"
            :class="item.check ? 'checkAmount' : ''"
            @click="checkGoods(item)"
          >
            <div class="goods display-f flex-row-jc-c">
              $&nbsp;{{ item.money }}
            </div>
            <!-- <div class="price display-f flex-row-jc-c">
                            <span class="currency">{{ item }}</span>
                            <span class="money">{{ item }}</span>
                        </div> -->
          </div>
          <div
            v-if="otherMoney"
            style="
              width: 100%;
              margin: 0px 6px 12px;
              padding: 12px;
              background: #fff;
              border-radius: 8px;
            "
          >
            <span>{{ otherMoney.name + "：$" }}&nbsp;&nbsp;</span>
            <a-input-number
              size="large"
              :min="otherMoney.min"
              :max="Number(otherMoney.max)"
              @change="changeOther"
              style="border-radius: 8px"
            />
            <span style="color: red">&nbsp;&nbsp;{{ otherMoney.desc }}</span>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tips">
          <span style="font-weight: bold">備註：</span>
          <p>
            1.該網站為官方指定唯一儲值管道。<br />
            2.儲值成功後，精靈幣會發送至遊戲賬號，請注意查收。<br />
            3.如有問題請聯繫官方臉書-<a
              href="https://www.facebook.com/MonsterinPocket/"
              >【神寶覺醒：Smash】</a
            >，尋找客服幫忙。<br />
            4.官網儲值指南：①登錄帳號→②點擊儲值方式→③點擊檔位→④點擊儲值→⑤跳轉儲值頁面→⑥支付成功→⑦登錄遊戲內點擊心儀的商品→⑧選擇錢包兌換方式→⑨儲值成功。<br />
            5.FB/GOOGLE/APPLE登錄帳號查詢:①登入遊戲介面→②點擊帳號登入，點擊密碼行旁邊的眼睛→③保存帳號和密碼，使用到官網登入<br />
            舉例：如果我是facebook登入，我的登錄帳號是fb開頭的fb******,密碼是6個數字。
          </p>
        </div>
        <div class="total">
          <div class="display-f flex-row-jc-e" style="color: #1890ff">
            <span style="font-size: 18px; font-weight: bold">價格：</span>
            <!-- <span style="margin-right: 6px;">{{ params.currency }}</span> -->
            <span style="font-size: 20px; font-weight: bold"
              >$&nbsp;{{ params.money ? params.money : "0.00" }}</span
            >
          </div>
          <div class="display-f flex-row-jc-c">
            <span>獲得：</span>
            <span>{{ desc ? desc : "無" }}</span>
          </div>
          <div class="display-f flex-row-jc-c">
            <span>兌換渠道：</span>
            <span style="color: #1890ff">{{
              params.pway ? params.pway : "無"
            }}</span>
          </div>
          <a-button
            type="primary"
            @click="buyNow()"
            style="width: 100%; font-size: 16px; height: 48px; margin-top: 24px"
            >儲值</a-button
          >
        </div>
      </div>
    </div>
    <!-- PC充值钻石返利 -->
    <div
      class="pay public-width display-f flex-row-js"
      v-show="showTab == 2 && rechargeType == 2"
    >
      <div class="left">
        <div
          class="left-item display-f"
          style="
            padding-bottom: 12px;
            border-bottom: 1px dashed rgb(170 170 170);
          "
        >
          <div style="margin-right: 24px">
            <label for="game">伺服器：</label>
            <span class="rolerColor">{{
              "[" + rolerData.DSID + "]" + rolerData.DSNAME
            }}</span>
          </div>
          <div>
            <label for="game">角色：</label>
            <span class="rolerColor">{{
              "[" + rolerData.DRLEVEL + "]" + rolerData.DRNAME
            }}</span>
          </div>
        </div>
        <div
          class="left-item display-f flex-row-jl-c"
          style="position: relative"
        >
          <label for="" style="width: 98px; display: block"
            >選擇兌換渠道：</label
          >
          <div class="payWay display-f flex-row-js">
            <div
              class="way"
              v-for="item in wayListNew"
              :key="item.id"
              :class="item.checked ? 'checkWay' : ''"
              @click="checkPayWayRebate(item)"
            >
              <!-- {{ item.desc }} -->
              <img :src="item.icon" alt="" />
            </div>
          </div>
        </div>
        <div class="left-item display-f flex-row-jl" style="flex-wrap: wrap">
          <div
            v-for="(item, index) in rebateGear"
            :key="index"
            class="amount display-f flex-column-jc"
            :class="item.checked ? 'checkAmount' : ''"
            @click="checkRebate(item)"
          >
            <div class="rebate display-f flex-row-jc-c">
              $&nbsp;{{ item.money }}
            </div>
            <div class="rebateGoods display-f flex-column-jc-c">
              <span :class="item.checked ? 'spanCheck' : ''">{{
                item.coin + "鑽石"
              }}</span>
              <span v-if="item.rebate" style="font-size: 14px; color: red">{{
                "贈送" +
                parseInt(item.rebate_value) +
                "鑽石"
              }}</span>
            </div>
            <img
              v-if="item.tag == 1"
              class="angleIcon"
              src="../../assets/img/recommend.png"
              alt=""
            />
            <img
              v-if="item.tag == 2"
              class="angleIcon"
              src="../../assets/img/discount.png"
              alt=""
            />
            <img
              v-if="item.tag == 3"
              class="angleIcon"
              src="../../assets/img/hot.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="tips">
          <!-- <span style="font-weight: bold;">備註：</span> -->
          <span v-html="tipsData" style="white-space: pre-wrap"></span>
        </div>
        <div class="total">
          <div class="display-f flex-row-jc-e" style="color: #1890ff">
            <span style="font-size: 18px; font-weight: bold">價格：</span>
            <!-- <span style="margin-right: 6px;">{{ params.currency }}</span> -->
            <span style="font-size: 20px; font-weight: bold"
              >$&nbsp;{{
                diamondsParams.money ? diamondsParams.money : "0.00"
              }}</span
            >
          </div>
          <div class="display-f flex-row-jc-c">
            <span>獲得：</span>
            <span>
              {{ rebateDesc.currency ? rebateDesc.currency : "無" }}
              <span v-if="rebateDesc.add" style="color: red">{{
                "+ " + rebateDesc.add
              }}</span>
            </span>
          </div>
          <div class="display-f flex-row-jc-c">
            <span>兌換渠道：</span>
            <span style="color: #1890ff">{{
              payGateParams.pway ? payGateParams.pway : "無"
            }}</span>
          </div>
          <a-button
            type="primary"
            @click="rebateCreateOrder()"
            style="width: 100%; font-size: 16px; height: 48px; margin-top: 24px"
            >儲值1</a-button
          >
        </div>
      </div>
    </div>
    <!-- 排行榜-PC -->
    <div
      class="pay public-width display-f flex-row-js"
      v-show="showTab == 2 && rechargeType == 3"
    >
      <div class="display-f flex-column left rankBox-pc">
        <h3 style="padding: 4px 0px; text-align: center; font-weight: bold">
          {{ payRankData.title }}
        </h3>
        <div
          class="display-f flex-row-js-c rank-item"
          v-for="(eve, id) in payRankData.list"
          :key="id"
        >
          <div v-if="id == 0" class="display-f flex-row-jc-c rankb rankb1">
            {{ id + 1 }}
          </div>
          <div v-else-if="id == 1" class="display-f flex-row-jc-c rankb rankb2">
            {{ id + 1 }}
          </div>
          <div v-else-if="id == 2" class="display-f flex-row-jc-c rankb rankb3">
            {{ id + 1 }}
          </div>
          <div v-else class="display-f flex-row-jc-c rankb">{{ id + 1 }}</div>
          <div>
            {{ eve.drname }}
            <span
              style="
                padding: 4px;
                background: rgb(255 215 0);
                border-radius: 4px;
                color: rgb(255 255 255);
              "
              v-if="rolerData.DRID == eve.drid"
              >本人</span
            >
          </div>
          <div>{{ "$ " + eve.money }}</div>
        </div>
      </div>
      <div class="right">
        <div class="tips" style="padding: 6px">
          <span v-html="payRankData.desc" style="white-space: pre-wrap"></span>
        </div>
      </div>
    </div>
    <div class="maskpay" v-if="showAec" @click="showAec = false">
      <div class="zs" @click.stop="">
        <span class="title">請選擇地區/通道</span>
        <a-cascader
          v-model:value="aecValue"
          size="large"
          :field-names="{ label: 'name', value: 'name', children: 'child' }"
          :options="optionsAec"
          placeholder="請選擇地區/通道"
          @change="checkAec"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getReqData,
  getWayListAPI,
  createOrderAPI,
  payGateAPI,
  getBalanceAPI,
  rebateCreateOrderAPI,
  rebatePayGateAPI,
  getRemarkAPI,
  getPayRankAPI,
  newWayListAPI,
  getPayNapeAPI,
} from "../../api/requests";
import secret from "../../api/secret";

export default {
  name: "Currency",
  data() {
    return {
      rechargeType: 2,
      payAmount: [],
      params: {
        pid: "",
        gid: "",
        duid: "",
        time: "",
        sign: "",
        dsid: "",
        dsname: "",
        drid: "",
        drname: "",
        uid: "",
        uname: "",
        token: "",
        money: "",
        pway: "",
        pay_type: "platform",
      },
      isTips: false,
      // 获取用户详情参数
      detailParams: {
        duid: "",
        time: "",
        sign: "",
        uname: "",
        uid: "",
        token: "",
      },
      wayParams: {
        duid: "",
        time: "",
        sign: "",
      },
      wayList: "",
      balance: 0,
      spinning: false,
      paycode: "",
      desc: "",
      otherMoney: "",
      rebateParma: {
        GID: "1000076",
      },
      rebateGear: "",
      rebateList: "",
      rebateExplain: {
        PID: "46",
        GID: "1000076",
        UID: "",
      },
      rebateDesc: {
        currency: "",
        add: "",
      },
      diamondsParams: {
        pid: "",
        gid: "",
        duid: "",
        time: "",
        sign: "",
        dsid: "",
        dsname: "",
        drid: "",
        drname: "",
        uid: "",
        uname: "",
        token: "",
        money: "",
        pway: "",
        pay_type: "game",
      },
      rolerData: {},
      payGateParams: {
        uid: "",
        uname: "",
        money: "",
        pway: "",
        paycode: "",
        moid: "",
      },
      temporary: {
        accountid: "", // 研发账号id
        roleid: "", // 角色id
        rechargeid: "", // 充值挡位id
        coin: "", // 挡位游戏币(砖石)
        rebate: "", // 返利百分比(小数)
      },
      isRebate: false,
      rebateTipsM: "",
      tipsData: "",
      dsname: "",
      drname: "",
      payRankData: "",
      optionsAec: [],
      showAec: false,
      aecValue: [],
      wayListNew: [],
    };
  },
  props: {
    showTab: {
      type: Number,
    },
  },
  created() {
    if (this.$store.state.rolerInfo) {
      this.rolerData = this.$store.state.rolerInfo;
    } else if (localStorage.getItem("rolerInfo")) {
      this.rolerData = JSON.parse(
        secret.des_decrypt(localStorage.getItem("rolerInfo"))
      );
    } else {
      this.rolerData = "";
    }
    // this.getRemark();
    // this.getPayRank();
  },
  mounted() {
    // this.getRebate();
  },
  methods: {
    init() {
      this.getWayList();
      this.getRemark();
      this.getPayRank();
      this.getRebate();
    },
    changeType(e) {
      this.rechargeType = e.target.value;
      // if (this.rechargeType == 2) {
      //     this.getRebate();
      // }
      this.getWayList();
      this.aecValue = [];
    },
    handleChange() {
      console.log("【選擇】");
    },
    checkPayWay(data) {
      this.isTips = false;
      for (const item in this.wayList) {
        this.wayList[item].checked = 0;
      }
      if (data.other) {
        this.otherMoney = data.other;
      } else {
        this.otherMoney = "";
      }
      data.checked = 1;
      if (data.type == "acepay") {
        this.optionsAec = data.child;
        this.showAec = true;
      }
      this.payAmount = data.moneys;
      this.payAmount.forEach((res) => {
        res.check = false;
      });
      this.params.pway = data.type;
      this.paycode = data.paycode;
    },
    checkGoods(item) {
      this.isTips = false;
      this.payAmount.forEach((res) => {
        res.check = false;
      });
      item.check = true;
      // this.params.goods = item.goods;
      // this.params.currency = item.currency;
      this.desc = item.desc;
      this.params.money = item.money;
    },
    changeOther(value) {
      this.payAmount.forEach((res) => {
        res.check = false;
      });
      this.params.money = value;
      this.desc = value + "精靈幣";
    },

    // 获取充值渠道&渠道充值挡位列表
    getWayList() {
      getWayListAPI(getReqData(this.wayParams)).then((res) => {
        this.wayList = res.data.data;
        for (const key in this.wayList) {
          if (this.wayList[key].checked == 1) {
            this.payAmount = this.wayList[key].moneys;
            this.params.pway = this.wayList[key].type;
            this.payGateParams.pway = this.wayList[key].type;
            this.diamondsParams.pway = this.wayList[key].type;
            this.paycode = this.wayList[key].paycode;
            if (this.wayList[key].other) {
              this.otherMoney = this.wayList[key].other;
            }
          }
        }
      });
    },
    // 提交订单
    buyNow() {
      this.isTips = false;
      if (!this.params.money) {
        this.$message.warning("請選擇充值金額");
        return;
      }
      if (this.params.pway == "acepay") {
        if (!this.params.pdata) {
          this.$message.warning("請選擇支付通道!");
          this.showAec = true;
          return;
        }
      } else {
        this.params.pdata = "";
      }
      this.spinning = !this.spinning;
      this.params.pid = 0;
      this.params.gid = 0;
      this.params.uid = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uid;
      this.params.uname = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uname;
      this.params.token = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).token;
      createOrderAPI(getReqData(this.params)).then((res) => {
        if (res.data.code == 0) {
          this.payGate(res.data.data, this.params.pdata);
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    // 支付网关接口
    payGate(data, pdata) {
      let payParams = {};
      payParams.uid = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uid;
      payParams.uname = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uname;
      payParams.pdata = pdata;
      payParams.money = this.params.money;
      payParams.pway = this.params.pway;
      payParams.paycode = this.paycode;
      payParams.moid = data.moid;
      payGateAPI(getReqData(payParams)).then((res) => {
        if (res.data.code == 0) {
          window.location.href = res.data.data.trade;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.spinning = !this.spinning;
      });
    },
    // 余额
    getBalance() {
      let balancePar = {};
      balancePar.uid = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uid;
      balancePar.uname = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uname;
      // balancePar.token = JSON.parse(localStorage.getItem('info')).token;
      getBalanceAPI(getReqData(balancePar)).then((res) => {
        this.balance = res.data.data.blance;
      });
    },

    getRebate() {
      let lsStr = {
        duid: "",
        time: "",
        sign: "",
        language: 1,
        pgid: 10,
      };
      newWayListAPI(lsStr).then((res) => {
        if (res.data.code == 0) {
          this.wayListNew = res.data.data;
          this.wayListNew.forEach((ele) => {
            if (ele.checked) {
              this.getRebateList(ele);
            }
          });
        }
      });

      // getRebateLevelAPI(this.rebateParma).then((res) => {
      //   this.rebateGear = res.data.data;
      //   this.rebateGear.forEach((item) => {
      //     item.checked = false;
      //   });
      //   this.getRebateList();
      // });
    },
    getRebateList(pay) {
      let lsStr = {
        duid: "",
        language: 1,
        pay_type: 'game',
        pgid: 10,
        pwayid: pay.pwayid,
        sign: "",
        time: "",
      };
      getPayNapeAPI(lsStr).then((res) => {
        if (res.data.code == 0) {
          this.rebateGear = res.data.data.list;
          this.rebateGear.forEach(ele => {
            if (ele.checked) {
              this.diamondsParams.money = ele.money
              this.temporary.rechargeid = ele.productid; // 充值挡位id
              this.temporary.coin = ele.coin; // 挡位游戏币(砖石)
              this.temporary.rebate = ele.rebate; // 返利百分比(小数)
              this.temporary.accountid = this.rolerData.ACCOUNTID; // 研发账号id
              this.temporary.roleid = this.rolerData.DRID; // 角色id
              this.isRebate = true;
              this.rebateTipsM = ele.money;
              this.rebateDesc.add =
                "贈送" +
                parseInt(ele.rebate_value) +
                "鑽石";
                  }
          })
        }
      });
      // this.rebateExplain.UID = JSON.parse(
      //   secret.des_decrypt(localStorage.getItem("info"))
      // ).uid;
      // getRebateListAPI(this.rebateExplain).then((res) => {
      //   this.rebateGear.forEach((item) => {
      //     for (let i = 0; i < res.data.data.length; i++) {
      //       if (item.RECHARGEID == res.data.data[i].RECHARGEID) {
      //         item = Object.assign(item, res.data.data[i]);
      //       }
      //     }
      //     item.checked = false;
      //   });
      // });
    },
    checkRebate(item) {
      this.rebateGear.forEach((item) => {
        item.checked = false;
      });
      item.checked = true;
      this.diamondsParams.money = item.money;
      this.payGateParams.money = item.money;
      this.rebateDesc.currency = item.coin + "鑽石";
      if (item.rebate) {
        this.temporary.rechargeid = item.productid; // 充值挡位id
        this.temporary.coin = item.coin; // 挡位游戏币(砖石)
        this.temporary.rebate = item.rebate; // 返利百分比(小数)
        this.temporary.accountid = this.rolerData.ACCOUNTID; // 研发账号id
        this.temporary.roleid = this.rolerData.DRID; // 角色id
        this.isRebate = true;
        this.rebateTipsM = item.money;
        this.rebateDesc.add =
          "贈送" +
          parseInt(item.rebate_value) +
          "鑽石";
      } else {
        this.isRebate = false;
        this.rebateTipsM = "";
        this.rebateDesc.add = "";
      }
    },
    checkPayWayRebate(data) {
      for (const item in this.wayListNew) {
        this.wayListNew[item].checked = 0;
      }
      data.checked = 1;
      if (data.type == "acepay") {
        this.optionsAec = data.child;
        this.showAec = true;
      }
      this.diamondsParams.pway = data.type;
      this.payGateParams.pway = data.type;
      this.paycode = data.paycode;
      this.getRebateList(data);
    },
    rebateCreateOrder() {
      if (!this.diamondsParams.money) {
        this.$message.warning("請選擇充值金額");
        return;
      }
      if (this.diamondsParams.pway == "acepay") {
        if (!this.diamondsParams.pdata) {
          this.$message.warning("請選擇支付通道!");
          this.showAec = true;
          return;
        }
      } else {
        this.diamondsParams.pdata = "";
      }
      this.spinning = !this.spinning;
      this.diamondsParams.pid = 46;
      this.diamondsParams.gid = 1000076;
      this.diamondsParams.dsid = this.rolerData.DSID;
      this.diamondsParams.dsname = this.rolerData.DSNAME;
      this.diamondsParams.drid = this.rolerData.DRID;
      this.diamondsParams.drname = this.rolerData.DRNAME;
      this.diamondsParams.uid = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uid;
      this.diamondsParams.uname = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uname;
      this.diamondsParams.token = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).token;
      if (this.isRebate) {
        this.diamondsParams = Object.assign(
          this.diamondsParams,
          this.temporary
        );
      }
      rebateCreateOrderAPI(getReqData(this.diamondsParams)).then((res) => {
        if (res.data.code == 0) {
          this.rebatePayGate(res.data.data, this.diamondsParams);
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    rebatePayGate(data, orderData) {
      this.payGateParams.uid = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uid;
      this.payGateParams.uname = JSON.parse(
        secret.des_decrypt(localStorage.getItem("info"))
      ).uname;
      this.payGateParams.pdata = orderData.pdata;
      this.payGateParams.paycode = this.paycode;
      this.payGateParams.moid = data.moid;
      this.payGateParams.money = orderData.money;
      // if (this.isRebate) {
      //     this.payGateParams = Object.assign(this.payGateParams, this.temporary)
      // }
      rebatePayGateAPI(getReqData(this.payGateParams)).then((res) => {
        if (res.data.code == 0) {
          window.location.href = res.data.data.trade;
        } else {
          this.$message.warning(res.data.msg);
        }
        this.spinning = !this.spinning;
      });
    },
    getRemark() {
      getRemarkAPI().then((res) => {
        this.tipsData = res.data.data.REMARK.split(/\\r\\n/g).join("<br/>");
      });
    },

    getPayRank() {
      let parStr = {
        pgid: 10,
        language: 1,
      };
      getPayRankAPI(getReqData(parStr)).then((res) => {
        if (res.data.code == 0) {
          this.payRankData = res.data.data;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },

    // 选择AEC支付通道
    checkAec(value, selectedOptions) {
      let _this = this;
      if (selectedOptions.length > 0) {
        this.diamondsParams.pdata = JSON.stringify({
          channelCode: selectedOptions[1].channelCode,
          country_code: selectedOptions[0].country_code,
        });
        this.params.pdata = JSON.stringify({
          channelCode: selectedOptions[1].channelCode,
          country_code: selectedOptions[0].country_code,
        });
        setTimeout(function () {
          _this.showAec = false;
        }, 1500);
        clearTimeout();
      } else {
        this.diamondsParams.pdata = "";
        this.aecValue = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pay {
  width: 100%;
  flex: 1;
  padding-top: 12px;
  .rankBox-pc {
    height: calc(100vh - 370px);
    min-height: 580px;
    margin-left: 12px;
    overflow: hidden;
    overflow-y: scroll;
    .rank-item {
      background: #ffffff;
      margin-bottom: 1px;
      padding: 6px 12px;
    }
    .rankb {
      width: 32px;
      height: 32px;
      padding: 6px;
      font-size: 14px;
      font-weight: bold;
    }
    .rankb1 {
      background: red;
      color: #ffffff;
      border-radius: 50%;
    }
    .rankb2 {
      background: orangered;
      color: #ffffff;
      border-radius: 50%;
    }
    .rankb3 {
      background: orange;
      color: #ffffff;
      border-radius: 50%;
    }
  }
}
.pay .left {
  flex: 1;
  padding: 16px;
  background: #efefef;
}
.left .left-item {
  margin-bottom: 24px;
}
.left .left-item .way {
  width: 104px;
  height: 56px;
  padding: 4px;
  margin-right: 24px;
  border-radius: 8px;
  border: 1px solid #cccccc;
}
.left .left-item .way:hover {
  box-shadow: 0px 0px 4px 4px #a0c6f7;
}
.checkWay {
  color: #fff;
  background: #c5d9ec;
  /* background-image: linear-gradient( rgb(11, 250, 218), #4b3feb); */
  box-shadow: 0px 0px 4px 4px #a0c6f7;
}
.sel {
  width: 30%;
}
.sel label {
  width: 68px;
  font-size: 16px;
  text-align: right;
}
.rolerColor {
  color: #f58220;
  font-size: 18px;
  font-weight: bold;
}
.amount {
  width: 15%;
  // width: 0.4rem;
  min-height: 81px;
  margin: 0px 6px 12px 6px;
  /* margin-bottom: 12px; */
  border-radius: 8px;
  background: #ffffff;
  /* font-size: 16px; */
  font-size: 0.0458rem;
  font-weight: bold;
  position: relative;
  .rebate {
    width: 100%;
    padding: 8px 12px 0px;
  }
  .rebateGoods {
    padding: 0px 12px 4px;
    color: #1890ff;
    .spanCheck {
      color: #ffffff;
    }
  }
  .angleIcon {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
.amount .goods {
  width: 100%;
  /* border-right: 1px solid #cccccc; */
  padding: 12px;
}
.amount .price {
  width: 50%;
  padding: 12px;
  color: #1890ff;
}
.currency {
  margin-right: 12px;
}
.amount:hover {
  box-shadow: 0px 0px 4px 4px #a0c6f7;
}
.checkAmount {
  color: #ffffff;
  background: #1890ff;
  box-shadow: 0px 0px 4px 4px #a0c6f7;
}
.checkAmount .price {
  color: #ffffff;
}
.pay .right {
  width: 360px;
  padding: 0px 12px;
}
.pay .right .tips {
  padding: 12px;
  margin-bottom: 12px;
  background: #efefef;
}
.pay .right .total {
  padding: 12px;
  background: #efefef;
}
.tab {
  height: 60px;
  padding: 0px 8px;
  font-weight: normal;
  margin: 0px 8px;
}
.tab2 {
  border-bottom: 4px solid #dedede;
  color: #666666;
}
.selectTab {
  color: #1890ff;
  /* font-weight: bold; */
  border-bottom: 4px solid #1890ff;
}
.payWay {
  width: 2.347rem;
  min-width: 820px;
  padding: 6px;
  overflow: hidden;
  border-radius: 6px;
  overflow-x: scroll;
  box-shadow: inset 18px 0 19px -18px rgb(145, 183, 226),
    inset -18px 0 18px -13px rgb(145, 183, 226);
}

.payWay::-webkit-scrollbar {
  width: 4px;
}
.payWay::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.payWay::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

/* 移动端 */
.mobile {
  padding: 12px 0px 186px;
  overflow: hidden;
  overflow-y: scroll;
  background: #e5e5e5;
  .mobile_wayList {
    width: 3.75rem;
    height: 68px;
    margin-bottom: 12px;
    overflow: hidden;
    overflow-x: scroll;
    font-size: 18px;
    padding: 4px 8px;
    background: #fff;
    box-shadow: inset 18px 0 19px -18px #a0c6f7,
      inset -18px 0 18px -13px #a0c6f7;
  }
}
.rankBox {
  margin: 12px;
  padding: 8px;
  .rank-item {
    background: #ffffff;
    margin-bottom: 1px;
    padding: 6px 12px;
  }
  .rankb {
    width: 32px;
    height: 32px;
    padding: 6px;
    font-size: 14px;
    font-weight: bold;
  }
  .rankb1 {
    background: red;
    color: #ffffff;
    border-radius: 50%;
  }
  .rankb2 {
    background: orangered;
    color: #ffffff;
    border-radius: 50%;
  }
  .rankb3 {
    background: orange;
    color: #ffffff;
    border-radius: 50%;
  }
}
.amount2 {
  width: 1.1rem;
  height: 81px;
  margin: 0px 6px 12px 6px;
  /* margin-bottom: 12px; */
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  .price {
    color: #1890ff;
  }
  .angleIcon {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
.currency2 {
  color: #ffffff;
}
.noCheck {
  background: #ffffff;
}
.payNow {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: #fff;
  width: 100%;
  height: 176px;
  padding-top: 12px;
  border-top: 1px solid #eeeeee;
  box-shadow: 0px 0px 4px 4px #eeeeee;
  text-align: center;
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* position: relative; */
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
.mobileTips {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 12px;
  width: 2.4rem;
  box-shadow: 0px 0px 4px 4px #eeeeee;
  z-index: 99;
}

.maskpay {
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .zs {
    width: 480px;
    max-width: 88vw;
    height: 300px;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-weight: bold;
      margin-bottom: 12px;
    }
  }
}
</style>
