var CryptoJS = require('crypto-js')
// var Base64 = require('Base64')

const md5 = require('md5')
// const Base64 = require('enc-base64');

var key = md5('gw.llmaitengff.com').toString()
var iv = 'sdyxgwhymt'

var crypto_key = CryptoJS.enc.Utf8.parse(key.substr(0, 24))
var crypto_iv = CryptoJS.enc.Utf8.parse(iv.substr(0, 8))

function des_encrypt(str) {
  if (typeof str == 'object') str = JSON.stringify(str);

  var encode_str = CryptoJS.TripleDES.encrypt(str, crypto_key, {
      iv: crypto_iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });

  return encode_str.toString();
}


function des_decrypt(str) {
  // let decData = new Base64().decode(str);
  str = str.replace('"', '').replace('"', '');
  let decData = str.toString(CryptoJS.enc.Utf8);
  // decData = CryptoJS.enc.Base64.parse(decData).toString(CryptoJS.enc.Utf8);

  var decrypt_str = CryptoJS.TripleDES.decrypt(decData, crypto_key, {
      iv: crypto_iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });
  let retStr = decrypt_str.toString(CryptoJS.enc.Utf8);
  return retStr;
}

export default {
  des_encrypt,
  des_decrypt,
}
