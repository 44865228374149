<template>
    <div
        class="resultReg display-f flex-column-jc public-width"
        :class="showTab == 1 ? 'mobileH' : 'pcH'"
    >
        <a-spin :spinning="spinning" tip="Loading..." style="height: 100%;">
            <div class="display-f flex-column-jc">
                <img v-if="status == 1" src="../assets/img/ok.png" alt="">
                <img v-if="status == 0" src="../assets/img/fla.png" alt="">
                <img v-if="status == -1" src="../assets/img/anm.png" alt="">
                <span>{{ tipsText }}</span>
                <a href="https://www.facebook.com/MonsterinPocket/" v-if="status == 0">如有疑問請聯繫VIP客服專員...</a>
                <span style="margin: 12px 0px;">訂單號：{{ order.uuid }}</span>
                <a-button type="primary" @click="determine()" style="width: 90px;font-size: 16px;height: 36px;margin-top: 24px;">确定</a-button>
            </div>
        </a-spin>
    </div>
</template>

<script>
import { getReqData, getOrderStatusAPI } from '../api/requests'

export default {
    name: 'payResult',
    data () {
        return {
            showTab: '',
            status: '',
            order: {
                uuid: ''
            },
            count: 0,
            initTimer: null,
            tipsText: '',
            time: 0,
            spinning: false
        }
    },
    created () {
        if (window.isMobile == 1) {
            this.showTab = 1;
        }
        if (window.isPC == 1) {
            this.showTab = 2;
        }
        this.$store.commit('saveUserInfo', JSON.parse(localStorage.getItem('info')));
        if (this.getParams('pway') == 'payermax') {
            if (this.getParams('status') == -1) {
                this.status = this.getParams('status');
                this.tipsText = '用户取消支付';
            }
            this.order.uuid = this.getParams('orderId');
            this.getOrder()
            this.spinning = !this.spinning;
        }
    },
    methods: {
        getParams (name, forward) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
            var r = window.location.search.substr(1).match(reg)
            if (r != null) return r[2]
            return forward
        },
        getOrder () {
            var _this = this;
            getOrderStatusAPI(getReqData(_this.order)).then((res) => {
                if (res.data.code == 0) {
                    clearTimeout(_this.initTimer);
                    _this.status = 1;
                    _this.tipsText = res.data.data.msg;
                    _this.spinning = !_this.spinning;
                } else {
                    _this.time = _this.time + 5;
                    _this.judgeInit(res.data);
                }
            })
        },
        judgeInit (data) {
            var _this = this;
            if (_this.count == 3) {
                clearTimeout(_this.initTimer);
                _this.status = 0;
                _this.tipsText = data.msg;
                _this.spinning = !_this.spinning;
            } else {
                _this.initTimer = setTimeout(function () {
                    _this.count++
                    _this.getOrder();
                }, 10000);
            }
        },
        // judgeInit () {
        //     var _this = this;
        //     _this.getOrder
        //     if (_this.count == 3) {
        //         clearTimeout(initTimer);
        //         _this.beforeLogin()
        //     } else {
        //         initTimer = setTimeout(function () {
        //             count++
        //             _this.judgeInit();
        //         }, 20000);
        //     }
        // },
        determine () {
            this.$router.push('/')
        }
    }
}
</script>

<style lang="scss" scoped>
    .resultReg {
        // height: calc(100vh - 216px);
        // margin: 88px 0px 12px;
        padding-bottom: 24px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 6px #e5e5e5;
    }
    .mobileH {
        margin-top: 88px;
        height: calc(100vh - 88px);
    }
    .pcH {
        height: calc(100vh - 216px);
        min-height: 540px;
    }
    img {
        width: 90px;
        height: 90px;
        margin: 24px 0px;
    }
</style>
