<template>
    <div class="footer display-f flex-row-jc">
        <div class="footer-content public-width_p">
            <div style="padding: 0px 6px;">
                <a href="https://www.facebook.com/MonsterinPocket/">聯繫我們</a>
                <span>|</span>
                <!-- <a href="">隱私政策</a>
                <span>|</span> -->
                <!-- <a href="">服務條例</a> -->
                <router-link  :to="{path:'/agreement'}" >服務條例</router-link>
            </div>
            <div>
                <span>【神寶覺醒：Smash】遊戲公司</span>
            </div>
            <div>
                <span>【神寶覺醒：Smash】遊戲公司 版權所有</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Foot'
    }
</script>

<style scoped>
    .footer {
        width: 100%;
        border-top: 2px solid #999999;
        background: rgba(229,229,229, 0.4);
    }
    .footer-content {
        padding: 30px 20px;
    }
    span {
        margin: 0px 6px;
    }
</style>
