<template>
    <div class="strategy-box">
        <div class="display-f flex-row-js-c top">
            <div class="display-f flex-row-js-c" style="flex: 1;">
                <!-- <div>关键词</div> -->
                <div style="margin-right: 12px;">
                    <a-input-search placeholder="請輸入關鍵字" style="width: 1rem; min-width: 240px;" v-model="params.GTITLE" @search="onSearch" />
                </div>
            </div>
            <!-- <div>
                <a-button type="primary" size="small" @click="addStrategy">发表</a-button>
            </div> -->
        </div>
        <div class="display-f flex-row-jl-c keyWord">
            <a-button type="primary" shape="round" size="small" v-for="(btn, index) in keyWord" :key="index" @click="keySearch(btn)">{{ btn.text }}</a-button>
        </div>
        <div class="list-content">
            <div class="display-f flex-row-js-c list-box"
                v-for="(article, index) in dataList"
                :key="index"
                @click="getDetail(article)"
            >
                <div class="img-box">
                    <img :src="article.COVERURL" alt="" srcset="">
                </div>
                <div class="content-box">
                    <h3 class="str-title"><span v-if="article.IS_TOP == '1'" style="font-size: 12px; padding: 4px 4px 2px; background: #f6c700; border-radius: 6px; color: #fff;">置頂</span> {{ article.GTITLE }}</h3>
                    <div class="text-box">{{ article.CONTENT }}</div>
                </div>
            </div>
        </div>
        <div style="padding-top: 10px; box-shadow: 0px -4px 6px -2px #e5e5e5;">
            <a-pagination size="small" :total="listTotal" @change="changePage" />
        </div>
    </div>
</template>

<script>
import { getArticleListAPI } from '../api/requests';

export default {
    name: 'strategyList',

    data() {
        return {
            params: {
                UID: '',    //UID
                PAGE: 1,	//page 分页
                ROWS: 10,	//分页每页条数（默认10）
                GTITLE: '',	//搜索的文章条件(按标题搜索)
                ART_TYPE: ''
            },
            dataList: [],
            listTotal: 0,
            keyWord: [
                { text: '全部攻略', value: '' },
                { text: '新服攻略', value: 1 },
                { text: '陣容推薦', value: 2 },
                { text: '玩法攻略', value: 3 },
            ]
        }
    },

    created() {
        this.getArticleList();
    },

    methods: {
        addStrategy() {
            this.$emit('childValue', 'add');
            // this.$router.push('/addStrategy')
        },
        getArticleList() {
            getArticleListAPI(this.params).then((res) => {
                if (res.data.code == 1) {
                    this.dataList = res.data.data;
                    this.listTotal = Number(res.data.total);
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch((data) => {
                console.log(data);
            })
        },
        changePage(val) {
            this.params.PAGE = val;
            this.getArticleList();
        },
        getDetail(val) {
            this.$router.push({ path: '/strategyDetail', query: { WID: val.ID } })
        },
        onSearch(value) {
            this.params.GTITLE = value;
            this.params.PAGE = 1;
            this.getArticleList();
        },
        keySearch(value) {
            this.params.PAGE = 1;
            this.params.ART_TYPE = value.value;
            this.getArticleList();
        }
    }
}
</script>

<style lang="scss" scoped>
.strategy-box {
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
        // padding-bottom: 12px;
    }
    .keyWord {
        background: rgb(239 239 239);
        border-radius: 12px;
        padding: 10px;
        margin: 12px 0px;
        button {
            padding: 0px 6px;
            margin-right: 4px;
        }
    }
    .list-content {
        flex: 1;
        overflow: hidden;
        overflow-y: scroll;
        border-top: 1.5px dashed rgb(170 170 170);
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background: #dedede;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track-piece {
            background: transparent;
        }
        .list-box {
            padding: 12px 0px;
            border-bottom: 1px solid #cdcdcd;
            .img-box {
                width: 100px;
                height: 81px;
                border-radius: 6px;
                background: #dedede;
                img {
                    width: 100px;
                    height: 81px;
                }
            }
            .content-box {
                flex: 1;
                padding-left: 12px;
                .text-box {
                    font-size: 13px;
                    color: #999999;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .str-title {
                    font-size: 15px;
                    color: #1890ff;
                }
            }
        }
        .list-box:hover {
            background: #efefef;
        }
        .list-box:hover .content-box .str-title {
            color:#FFA500;
        }
    }
    
    
}
</style>
