<template>
    <div :class="(showTab == 1) ? 'mobileHs': 'pcHs'">
        <add-strategy v-if="strategy == 'add'" @childValue="childValue"></add-strategy>
        <strategy-list v-if="strategy == 'list'" @childValue="childValue"></strategy-list>
    </div>
</template>

<script>
import addStrategy from './addStrategy.vue';
import strategyList from './strategyList.vue';

export default {
    name: 'strategy',
    components: {
        addStrategy,
        strategyList
    },

    props: {
        showTab: {
            required: false,
            type: Number
        }
    },

    data() {
        return {
            strategy: 'list'
        }
    },

    methods: {
        childValue(val) {
            this.strategy = val;
        }
    }
}
</script>

<style lang="scss" scoped>
    .mobileHs {
        height: calc(100vh - 112px);
    }
    .pcHs {
        min-height: 650px;
        height: calc(100vh - 300px);
        // overflow: hidden;
        // min-height: 540px;
    }
</style>
